import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Kerala = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Circuit royal du Karnataka au goût des épices du Kerala</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bangalore.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Bangalore</h2>
        <p>
          À votre arrivée à l'aéroport de Bangalore, vous serez accueilli et
          transféré à votre hôtel. Prenez le temps de vous reposer et de vous
          acclimater à la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/hassan.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Bangalore - Hassan </h2>
        <p>
          Quittez Bangalore et partez pour Hassan. En chemin, visitez les
          temples d'Halebid et Belur, connus pour leur architecture exquise.
          Arrivée à Hassan et enregistrement à votre hôtel.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/belur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Hassan - Halebid - Belur - Hassan</h2>
        <p>
          Après le petit-déjeuner à Hassan, partez pour une excursion d'une
          journée pour visiter les magnifiques temples d'Halebid et Belur.
          Admirez l'architecture exquise des temples de Hoysaleswara à Halebid
          et de Chennakesava à Belur, témoins de l'ancienne splendeur de la
          dynastie Hoysala. Après la visite, retournez à Hassan et passez la
          nuit à votre hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mysore.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Hassan - Mysore</h2>
        <p>
          De Hassan, dirigez-vous vers Mysore, une ville historique et
          culturelle renommée. En chemin, visitez Sravanabelagola, célèbre pour
          sa statue de Gomateshwara, l'une des plus grandes statues
          monolithiques du monde. À Mysore, visitez le palais de Mysore, les
          jardins de Brindavan et explorez le marché local. Passez la nuit à
          Mysore.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/ooty.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Mysore - Ooty</h2>
        <p>
          Quittez Mysore et partez pour Ooty, une charmante station de montagne
          située dans les Nilgiris. Profitez du trajet pittoresque en train
          Nilgiri Mountain Railway, classé au patrimoine mondial de l'UNESCO.
          Une fois à Ooty, détendez-vous et admirez les paysages magnifiques.
          Passez la nuit à Ooty.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mettu.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Mettupalayam - Coimbatore</h2>
        <p>
          De Ooty, redescendez vers Mettupalayam en empruntant le fameux train à
          vapeur Nilgiri Mountain Railway. À Mettupalayam, continuez votre
          voyage vers Coimbatore, une ville animée du Tamil Nadu. Explorez les
          attractions de la ville et profitez de la cuisine locale. Passez la
          nuit à Coimbatore.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kochin.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Coimbatore - Cochin</h2>
        <p>
          Partez de Coimbatore et voyagez vers Cochin, une ville portuaire
          historique du Kerala. Explorez le quartier de Fort Kochi, célèbre pour
          ses influences coloniales, ses filets de pêche chinois et son charme
          unique. Visitez le palais hollandais, l'église Saint-François et
          assistez à un spectacle de danse Kathakali. Passez la nuit à Cochin.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kochin2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Cochin</h2>
        <p>
          Profitez d'une journée complète pour explorer Cochin. Visitez le
          quartier juif, la basilique de Santa Cruz, l'église St. Francis et le
          palais de Mattancherry. Promenez-vous dans les ruelles étroites et
          découvrez l'atmosphère cosmopolite de la ville. Passez la nuit à
          Cochin.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/munnar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Cochin - Munnar</h2>
        <p>
          Quittez Cochin et partez pour Munnar, une station de montagne
          pittoresque nichée au cœur des plantations de thé verdoyantes.
          Profitez du paysage enchanteur, visitez les jardins de roses, les
          plantations de thé et découvrez les cascades pittoresques. Passez la
          nuit à Munnar.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/periyar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Munnar - Periyar</h2>
        <p>
          De Munnar, continuez votre voyage vers Periyar, célèbre pour sa
          réserve naturelle abritant une riche biodiversité. Profitez d'une
          croisière en bateau sur le lac Periyar pour observer la faune sauvage,
          notamment des éléphants, des tigres, des sambar et une variété
          d'oiseaux. Passez la nuit à Periyar.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/allep.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Periyar - Allepey</h2>
        <p>
          Partez de Periyar et rendez-vous à Allepey, connue pour ses
          magnifiques backwaters. Embarquez à bord d'une péniche traditionnelle
          et profitez d'une croisière paisible le long des canaux, des rizières
          et des villages pittoresques. Imprégnez-vous de l'atmosphère sereine
          et passez la nuit à bord de la péniche.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kovalam.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 12 : Allepey - Kovalam</h2>
        <p>
          De Allepey, voyagez vers Kovalam, une célèbre station balnéaire
          réputée pour ses plages de sable doré et ses eaux cristallines.
          Profitez du temps libre pour vous détendre sur la plage, faire du
          surf, profiter de massages ayurvédiques ou explorer les environs.
          Passez la nuit à Kovalam.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kanya.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 13 : Kovalam - Kanyakumari - Kovalam</h2>
        <p>
          Faites une excursion d'une journée à Kanyakumari, le point le plus au
          sud de l'Inde continentale. Visitez le temple de Kumari Amman, le
          mémorial de Vivekananda et le phare de Kanyakumari offrant une vue
          panoramique sur la confluence des océans Indien, d'Arabie et de la
          baie du Bengale. Retournez à Kovalam pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 14 : Kovalam - Trivandrum - FRANCE</h2>
        <p>
          Quittez Kovalam et partez pour Trivandrum, la capitale du Kerala.
          Visitez le temple de Padmanabhaswamy, un temple hindou ancien et
          vénéré, ainsi que d'autres sites historiques et culturels de la ville.
          Ensuite, transfert à l'aéroport de Trivandrum pour votre vol de retour
          vers la France, emportant avec vous des souvenirs inoubliables de
          votre voyage au Kerala.
        </p>
      </div>
    </div>
  );
};

export default Kerala;
