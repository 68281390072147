import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Destination = () => {
  const destinations = [
    {
      id: 1,
      name: "Voyage de triangle d'or",
      slug: "goldentriangle",
      image: "/golderntriangle.jpg",
    },
    {
      id: 2,
      name: "Le grand tour de l'Inde du Sud de 21 jours",
      slug: "southindia",
      image: "/southindia.jpg",
    },
    {
      id: 3,
      name: "Circuit royal du Karnataka au goût des épices du Kerala",
      slug: "kerala",
      image: "/kerala4.jpg",
    },
    {
      id: 4,
      name: "Visite l'incroyable Gujarat",
      slug: "gujarat",
      image: "/kuttcch.jpg",
    },
    {
      id: 5,
      name: "Visite de l'Odisha et du Chhattisgarh",
      slug: "bhubaneswar",
      image: "/orissa.jpg",
    },
    {
      id: 6,
      name: "Circuit sacré Delhi à Varanasi",
      slug: "delhi",
      image: "/varanasi.jpg",
    },
    {
      id: 7,
      name: "Randonnée en montagne",
      slug: "himanchal1",
      image: "/himanchal2.jpg",
    },
    {
      id: 8,
      name: "Trekking dans la nature",
      slug: "himanchal2",
      image: "/himanchal.jpg",
    },
    {
      id: 9,
      name: "Trekking dans l'Himanchal",
      slug: "himanchal3",
      image: "/himanchal3.jpg",
    },
    {
      id: 10,
      name: "Circuit royal de 11 jours",
      slug: "maharaja",
      image: "/rajasthan.jpg",
    },
    {
      id: 11,
      name: "Visite d'Odhisa",
      slug: "odhisa",
      image: "/odhisa.jpg",
    },
    {
      id: 12,
      name: "Visite de la tribu d'Odhisa",
      slug: "tribal",
      image: "/odhisa2.jpg",
    },
    {
      id: 13,
      name: "Tournée spirituelle dans la vallée du Gange",
      slug: "spiritual1",
      image: "/uttrakhand.jpg",
    },
    {
      id: 14,
      name: "Circuit de culture et de spiritualité dans la vallée du Dalai Lama",
      slug: "spiritual2",
      image: "/amritsar.jpg",
    },
    {
      id: 15,
      name: "En route pour l’Himalaya !",
      slug: "ladakh",
      image: "/ladakh.jpg",
    },
  ];

  return (
    <div className="container mx-auto p-2 md:py-8">
      <Helmet>
        <meta
          name="description"
          content="Explorez les diverses merveilles de l'Inde grâce à nos itinéraires personnalisés pour des voyages inoubliables. Immergez-vous dans la tapisserie culturelle des destinations captivantes du Guide francophone en Inde, de l'Himalaya majestueux aux eaux tranquilles du Kerala. Découvrez les festivals vibrants de Rann in Kutch et l'envoûtant festival des cerfs-volants au Gujarat. Embarquez pour une aventure dans le triangle d'or ou plongez dans le royaume enchanteur de l'Inde du Sud. Nos expériences sur mesure offrent un mélange parfait de patrimoine, de nature et d'aventure, garantissant une exploration extraordinaire de l'incroyable pays qu'est l'Inde."
        />
        <meta
          name="keywords"
          content="carnet de voyage, voyage de triangle d'or, tour de l'Inde du Sud, circuit de Kerala, circuit de Karnataka, visite de Gujarat, visite de l'Odisha et du Chattisgarh, circuit de Delhi, circuit de Varanasi, trekking dans la nature, trekking dans l'Himachal, visite de la tribu d'Odisha, la vallée du Gange, la vallée de Dalai Lama"
        />
      </Helmet>
      <h2 className="text-3xl font-bold text-center mb-4">Carnet de voyage</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {destinations.map((destination) => (
          <div
            key={destination.id}
            className="bg-white rounded-lg shadow-lg p-4 hover:shadow-2xl transition-all duration-300"
          >
            <Link to={`/destinations/${destination.slug}`}>
              <img
                className="w-full h-48 object-cover rounded-t-lg"
                src={destination.image}
                alt={destination.name}
              />
              <h3 className="text-xl font-bold mt-4">{destination.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destination;
