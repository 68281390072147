import React from 'react';
import { Link } from 'react-router-dom';

const YogaTourComponent = () => {
  return (
    <div
      className="bg-cover bg-center m-6 h-auto md:h-96 flex items-center justify-center" // Responsive height classes added
      style={{ backgroundImage: 'url("yogatravel.jpg")' }} // Replace with the actual path to your yoga background image
    >
      <div className="bg-opacity-75 p-8 rounded-lg bg-gray-800 text-white text-center">
        <h1 className="text-4xl font-bold mb-4">Tour de Yoga à Rishikesh</h1>
        <p className="text-lg mb-8">
          Plongez dans la sérénité de Rishikesh avec nos circuits de yoga guidés. Imprégnez-vous de
          l'atmosphère spirituelle, apprenez les anciennes pratiques du yoga et régénérez votre esprit
          et votre corps. Nos instructeurs experts vous guideront à travers des sessions de yoga
          transformatrices au milieu des paysages pittoresques de Rishikesh.
        </p>
        <Link to="/contact">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            Réservez maintenant
          </button>
        </Link>
      </div>
    </div>
  );
};

export default YogaTourComponent;
