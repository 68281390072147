import React from "react";
import blogData from "../components/BlogData";
import { Helmet } from "react-helmet-async";

const Blogs = () => {
  const reversedBlogData = [...blogData].reverse();

  return (
    <div className="max-w-6xl mx-auto px-4 m-2 sm:px-6 lg:px-8">
      <Helmet>
        <meta
          name="description"
          content="Découvrez un trésor de blogs de voyage captivants et d'expériences enrichissantes vécues aux quatre coins de l'Inde. Notre collection de récits de voyage soigneusement sélectionnés vous plonge dans les merveilles culturelles de villes animées, de paysages tranquilles et de joyaux cachés.Explorez le patrimoine diversifié du Triangle d'or, embarquez pour des aventures qui vous feront vibrer dans les paysages pittoresques de l'Himalaya, et découvrez l'attrait mystique des temples anciens et des rivières sacrées.Rejoignez-nous pour un voyage virtuel à travers la myriade de couleurs, de saveurs et de traditions de l'Inde, et partagez avec nous des histoires inoubliables qui inspireront l'envie de voyager qui sommeille en vous."
        />
        <meta
          name="keywords"
          content="Festival du cerf-volant, Gujrat festival, Festival de Rann à Kutch, Festival de Dusshera à Mysore, Festival de Gangaur à Jaipur, Festival du chameau à Pushkar, Festival de Diwali au Rajasthan, Festival de Holi à Mathura, Fête d'Onam au Kerala"
        />
      </Helmet>
      <h1 className="text-3xl font-semibold text-center mt-8 mb-4">Blogs</h1>
      <div className="grid gap-6">
        {reversedBlogData.map((blog) => (
          <div
            key={blog.id}
            className="bg-white shadow-lg rounded-lg overflow-hidden"
          >
            <img
              src={blog.image}
              alt={blog.name}
              className="h-64 w-full object-cover object-center p-2"
            />
            <div className="p-4">
              <h3 className="text-gray-900 text-xl font-semibold mb-2">
                {blog.name}
              </h3>
              <p className="text-gray-400 mb-2">{blog.date}</p>
              <p className="text-gray-600 text-justify">{blog.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
