import React from "react";
import blogData from "../components/BlogData";

const HomeBlogs = () => {
  // Get the last two latest blogs
  const latestBlogs = blogData.slice(-2);

  return (
    <div>
      {/* Render the last two latest blogs */}
      <div className="max-w-6xl mx-auto px-4 m-2 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-semibold text-center mt-8 mb-4">Blogs</h1>
        <div className="grid gap-6">
          {latestBlogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <img
                src={blog.image}
                alt={blog.name}
                className="h-64 w-full object-cover object-center"
              />
              <div className="p-4">
                <h3 className="text-gray-900 text-xl font-semibold mb-2">
                  {blog.name}
                </h3>
                <p className="text-gray-400 mb-2">{blog.date}</p>
                <p className="text-gray-600 text-justify">{blog.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBlogs;
