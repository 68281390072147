import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Spiritual2 = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">
        circuit de culture et de spiritualité dans la vallée du Dalai Lama
      </h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          Après votre arrivée à Delhi, l'une des principales villes de l'Inde,
          prenez le temps de vous installer et de vous acclimater à
          l'environnement vibrant de cette métropole. Vous pouvez commencer
          votre voyage en explorant les attractions emblématiques de la ville,
          telles que le Fort Rouge, le Qutub Minar, le temple du Lotus et la
          porte de l'Inde. Plongez-vous dans l'histoire et la culture de la
          capitale indienne en visitant les musées, les marchés animés et en
          dégustant la délicieuse cuisine locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/chandigarh.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Chandigarh</h2>
        <p>
          Le matin, quittez Delhi pour vous rendre à Chandigarh, la première
          ville planifiée de l'Inde, conçue par l'architecte Le Corbusier.
          Profitez d'une visite des célèbres jardins de la ville, dont le rocher
          de la Rose, le jardin du Rocher et le jardin des roses, qui offrent
          des espaces paisibles pour se détendre et se ressourcer. Découvrez
          également le musée du Gouvernement, qui abrite des collections d'art
          et d'artisanat de la région.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/amritsar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 3 : Chandigarh à Amritsar
        </h2>
        <p>
          Partez de Chandigarh pour vous rendre à Amritsar, la ville sainte des
          Sikhs. Le point culminant de votre visite sera le célèbre temple d'or
          (Harmandir Sahib), un lieu de culte sacré qui accueille des milliers
          de pèlerins chaque jour. Imprégnez-vous de l'atmosphère spirituelle et
          de la musique sacrée (kirtan) qui résonne dans le temple. Ne manquez
          pas la cérémonie de fermeture de la frontière indo-pakistanaise à
          Wagah, qui offre un spectacle coloré et énergique.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/amritsar2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Amritsar</h2>
        <p>
          Passez une deuxième journée à Amritsar pour explorer davantage la
          ville. Visitez la vieille ville et promenez-vous dans ses ruelles
          étroites, découvrez l'histoire et la signification du temple d'or au
          musée du temple d'or, et découvrez le passé tumultueux de la région à
          travers le musée de la partition. Profitez de la cuisine locale, en
          particulier les plats traditionnels tels que le sarson ka saag et le
          makki di roti.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/dharamshala.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 5 : Amritsar à Dharamshala
        </h2>
        <p>
          Quittez Amritsar et voyagez vers Dharamshala, une ville située dans
          les contreforts de l'Himalaya. Connue comme le lieu de résidence du
          dalaï-lama en exil, Dharamshala offre une atmosphère paisible et
          spirituelle. Visitez le temple de Bhagsunath, un site sacré, et
          promenez-vous dans les rues de la ville pour découvrir l'influence
          tibétaine dans l'architecture, les magasins d'artisanat et les
          restaurants proposant de délicieux plats tibétains.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/mclodganj.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 6 : Dharamshala à McLeodganj
        </h2>
        <p>
          Poursuivez votre exploration de Dharamshala en visitant McLeodganj,
          située à proximité. C'est un centrespirituel et culturel tibétain où
          vous pourrez visiter le temple de Tsuglagkhang, qui est également la
          résidence du dalaï-lama. Admirez les magnifiques peintures murales et
          les sculptures bouddhistes, et explorez le musée du Tibet pour en
          apprendre davantage sur l'histoire et la culture tibétaines. Profitez
          de l'atmosphère paisible de McLeodganj en vous promenant dans les
          ruelles colorées, en visitant les boutiques d'artisanat et en
          dégustant des spécialités tibétaines dans les restaurants locaux.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 7 : Dharamshala à Delhi (départ)
        </h2>
        <p>
          Revenez à Delhi depuis Dharamshala pour votre départ. Profitez d'un
          dernier jour dans la capitale indienne pour explorer les marchés
          animés, faire du shopping et savourer les délices culinaires avant de
          quitter l'Inde. Vous pouvez également visiter des sites historiques
          tels que la tombe de Humayun, le temple du Lotus ou profiter d'une
          balade en bateau sur la rivière Yamuna. Faites vos adieux à l'Inde
          avec des souvenirs durables de votre voyage dans le nord du pays.
        </p>
      </div>
    </div>
  );
};

export default Spiritual2;
