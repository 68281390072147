import React from "react";

const AboutComponent = () => {
  return (
    <div className="bg-gray-100  flex flex-col justify-center items-center p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-indigo-700 mb-4">
          Vacances en Inde avec Guide francophone en Inde Namaste
        </h1>
        <h2 className="text-2xl font-bold text-center text-indigo-600 mb-6">
          A propos de nous
        </h2>
        <p className="text-lg text-justify leading-relaxed">
          Voyagez en Inde en utilisant nos services de guide pour les clients
          français et anglais en Inde. Bienvenue à notre groupe de guides
          individuels qui se consacrent à fournir des services de voyage
          exceptionnels et voyage pas cher à des clients français et anglais qui
          explorent diverses destinations en Inde. Nous sommes passionnés par la
          beauté naturelle et les diverses traditions de ce pays incroyable.
        </p>
        <div className="flex justify-center mt-4">
          <a
            href="/about"
            className="text-white bg-indigo-500 px-6 py-2 rounded-full hover:bg-indigo-700 transition duration-300 ease-in-out"
          >
            Cliquez ici pour plus d'informations
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
