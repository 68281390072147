import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    beforeChange: (currentSlide, nextSlide) => {
      // Notify the Slider component to re-render on slide change
      // This will prevent the text from overlapping on slides
      if (typeof window !== "undefined") {
        window.dispatchEvent(new Event("resize"));
      }
    },
  };

  const sliderItems = [
    {
      image: "/slider6.jpg",
      caption: "Bienvenue sur notre site",
      description:
        "Nous sommes ravis de vous accueillir et d'entamer ensemble ce voyage passionnant.",
    },
    {
      image: "/slid1.jpg",
      caption: "Le Taj Mahal emblématique au lever du soleil",
      description:
        "Admirez la beauté intemporelle du Taj Mahal alors que les premiers rayons du soleil embrassent délicatement sa façade blanche comme l'ivoire.",
    },
    {
      image: "/slid3.jpg",
      caption: "Ladakh Enchantant : Le Pays des Hauts Passages",
      description:
        "Bienvenue dans la terre mystique de Ladakh, une région à couper le souffle nichée dans la partie la plus septentrionale de l'Inde.",
    },
    {
      image: "/slid4.jpg",
      caption:
        "Croisière à travers la sérénité : L'expérience des péniches du Kerala",
      description:
        "Montez à bord d'une péniche du Kerala et embarquez pour un voyage de tranquillité à travers les paisibles backwaters du sud de l'Inde.",
    },
    {
      image: "/slid5.jpg",
      caption: "Merveilles antiques : Les majestueux forts du Rajasthan",
      description:
        "Remontez le temps et plongez-vous dans la grandeur des forts du Rajasthan, qui se dressent fièrement comme les gardiens de l'histoire.",
    },
    {
      image: "/slid6.jpg",
      caption: "Conquérir les hauteurs : Trekking dans les puissants Himalayas",
      description:
        "Embarquez pour une aventure épique à travers la nature sauvage impétueuse de l'Himalaya, où chaque pas vous rapproche des cieux.",
    },
    {
      image: "/slid7.jpg",
      caption:
        "Les merveilles indomptées de l'Inde : L'aventure de la faune vous attend",
      description:
        "Partez pour une aventure extraordinaire dans la faune de l'Inde, une terre grouillante d'écosystèmes divers et d'une riche diversité de la faune.",
    },
    {
      image: "/slid8.jpg",
      caption:
        "Vibrantes célébrations d'Onam : Pookalams colorés et rassemblements joyeux",
      description:
        "Témoin de l'essence de la riche culture du Kerala alors que l'État prend vie pendant les grandes festivités d'Onam.",
    },
    {
      image: "/slid9.jpg",
      caption:
        "Demeures sacrées : Les temples de la splendeur spirituelle de l'Inde",
      description:
        "Contemplez la majesté des temples de l'Inde, où la spiritualité et la brillance architecturale s'entremêlent pour créer des monuments d'une dévotion impressionnante.",
    },
    {
      image: "/slid10.jpg",
      caption: "Sérénité sur les rives : Les superbes plages de l'Inde",
      description:
        "Découvrez la beauté captivante des plages de l'Inde, où le sable doré rencontre les eaux azur de la mer d'Arabie, du golfe du Bengale et de l'océan Indien.",
    },
    {
      image: "/slid11.jpg",
      caption:
        "Dévoiler le cœur de l'Inde : S'immerger dans le charme de la vie villageoise",
      description:
        "Expérimentez l'attrait rustique d'une visite de village indien, où le temps ralentit et les joies simples de la vie prennent le devant de la scène.",
    },
    {
      image: "/slid12.jpg",
      caption: "Voyage au toit du monde : Aventures himalayennes en attente",
      description:
        "Embarquez pour un voyage unique dans l'imposant Himalaya, un royaume de sommets impressionnants, de cultures anciennes et de nature sauvage.",
    },
    {
      image: "/slid15.jpg",
      caption:
        "Splendeurs architecturales : Explorer les bâtiments emblématiques du grand tour de l'Inde",
      description:
        "Entamez une odyssée architecturale à travers l'Inde, où les traditions intemporelles et les merveilles modernes se côtoient.",
    },
    {
      image: "/slid17.jpg",
      caption:
        "Paysages majestueux : Explorer la beauté enchanteresse de Ladakh",
      description:
        "Plongez-vous dans la beauté éthérée du Ladakh, une terre de terrains accidentés, de cieux azur et de monastères anciens.",
    },
    {
      image: "/slid18.jpg",
      caption:
        "Voyage vers l'illumination : Suivre les traces du bouddhisme en Inde",
      description:
        "Pénétrez dans le royaume sacré du bouddhisme en Inde, où les anciens monastères et les stupas sereins détiennent les clés d'une sagesse spirituelle profonde.",
    },
    {
      image: "/slid19.jpg",
      caption:
        "Une tapisserie de spiritualité : Entreprendre un voyage sacré à travers l'Inde",
      description:
        "Lancez-vous dans une odyssée spirituelle en Inde, où le sacré coexiste avec le séculaire et le divin se révèle dans chaque aspect de la vie.",
    },
  ];

  return (
    <div className="slider-banner relative">
      <Slider {...settings}>
        {sliderItems.map((item, index) => (
          <div key={index} className="relative h-96 md:h-screen">
            <img
              src={item.image}
              alt={`Slider ${index + 1}`}
              className="w-full h-full md:h-screen object-c"
            />
            <div className="absolute bottom-0 left-0 right-0 pl-4 pr-4 text-center bg-gray-800 bg-opacity-75 rounded-lg">
              <h3 className="text-white md:text-3xl font-bold">{item.caption}</h3>
              <p className="text-white text-sm md:text-lg mt-2">{item.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderBanner;
