import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Delhi = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">
        Circuit Delhi Varanasi
      </h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          À votre arrivée à Delhi, enregistrez-vous à l'hôtel et reposez-vous.
          Passez la journée à explorer les rues animées, les monuments
          historiques et les marchés animés de la vieille ville de Delhi,
          notamment Jama Masjid, Chandni Chowk et le Fort Rouge. En soirée,
          visitez India Gate et imprégnez-vous de l'atmosphère animée de la
          ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/mandawa.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi à Mandawa</h2>
        <p>
          Après le petit-déjeuner, partez de Delhi et conduisez jusqu'à Mandawa,
          une charmante ville de la région de Shekhawati au Rajasthan.
          Enregistrez-vous à votre hôtel à votre arrivée et prenez un peu de
          temps pour vous détendre. Explorez les célèbres havelis de Mandawa,
          richement décorées de fresques magnifiques. Visitez le Fort de
          Mandawa, une structure imposante qui témoigne de la grandeur
          architecturale de la région.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bikaner.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Mandawa à Bikaner</h2>
        <p>
          Quittez Mandawa le matin et poursuivez votre voyage jusqu'à Bikaner. À
          votre arrivée à Bikaner, enregistrez-vous à votre hôtel et
          détendez-vous. Explorez le magnifique Fort de Junagarh, qui présente
          une fusion captivante des styles architecturaux rajpoute et moghol.
          Rendez visite au temple Karni Mata à Deshnok, connu pour ses rats
          résidents insolites. En soirée, plongez-vous dans les marchés colorés
          de Bikaner.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jaisalmer.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Bikaner - Deshnok - Jaisalmer</h2>
        <p>
          Quittez Bikaner et poursuivez votre voyage jusqu'à Jaisalmer, connue
          sous le nom de "Cité dorée". Si vous le souhaitez, arrêtez-vous à
          Deshnok pour revisiter le temple Karni Mata. Arrivez à Jaisalmer,
          enregistrez-vous à votre hôtel, puis prenez un peu de temps pour vous
          détendre. Profitez d'une soirée libre pour explorer les rues
          enchanteuses et les bazars de Jaisalmer.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jaisalmer2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Jaisalmer - Khouri - Jaisalmer</h2>
        <p>
          Partez en excursion d'une journée à Khuri, un village du désert situé
          près de Jaisalmer. Vivez une expérience de safari à dos de chameau et
          aventurez-vous dans les fascinantes dunes de sable du désert du Thar.
          Profitez de performances culturelles mettant en avant la musique
          folklorique traditionnelle et la danse, et dégustez un délicieux dîner
          rajasthanais. Retournez à Jaisalmer et passez la nuit à votre hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jaisalmer3.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Jaisalmer</h2>
        <p>Journée libre à Jaisalmer .</p>
        <p>
          Commencez la journée en visitant le Fort de Jaisalmer, site classé au
          patrimoine mondial de l'UNESCO, renommé pour son architecture complexe
          et ses vues panoramiques. Explorez les Havelis de Patwon Ki, un
          ensemble de cinq havelis finement sculptés. Visitez le lac Gadisar,
          une oasis paisible entourée de temples et de ghats. En soirée,
          promenez-vous dans les marchés locaux et profitez d'une séance de
          shopping d'artisanat et de textiles.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jodhpur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Jaisalmer à Jodhpur</h2>
        <p>
          Départ de Jaisalmer pour Jodhpur, la "Ville bleue". À votre arrivée à
          Jodhpur, visitez le majestueux fort de Mehrangarh, l'un des plus
          grands forts de l'Inde. Explorez les palais opulents et les jardins
          bien entretenus à l'intérieur du fort. Continuez votre visite avec
          Jaswant Thada, un mémorial en marbre dédié au Maharaja Jaswant Singh
          II. Plongez dans l'atmosphère animée des bazars de Jodhpur et
          découvrez l'artisanat local, notamment les textiles, la poterie et les
          bijoux.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jodhpur2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Jodhpur - Village Bishnoi - Jodhpur</h2>
        <p>
          Partez pour une excursion d'une journée dans le village de Bishnoi,
          situé à proximité de Jodhpur. Découvrez le mode de vie traditionnel
          des Bishnois, une communauté dévouée à la protection de la nature et
          des animaux. Rencontrez les habitants, observez leurs activités
          quotidiennes et apprenez-en plus sur leurs pratiques culturelles et
          leurs métiers artisanaux. Profitez également de la beauté naturelle
          des environs, avec ses paysages désertiques et ses villages
          pittoresques.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/udaipur2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Jodhpur - Ranakpur - Kumbhalgarh - Udaipur</h2>
        <p>
          Quittez Jodhpur et partez pour Udaipur en passant par Ranakpur et
          Kumbhalgarh. À Ranakpur, visitez les célèbres temples jaïns, réputés
          pour leur architecture exquise et leurs sculptures détaillées. À
          Kumbhalgarh, explorez le fort massif, qui offre une vue imprenable sur
          les collines environnantes. À votre arrivée à Udaipur, la "Venise de
          l'Est", profitez du reste de la journée pour vous détendre et vous
          imprégner de l'atmosphère romantique de cette ville lacustre.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/udaipur3.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Udaipur</h2>
        <p>
          Explorez Udaipur, l'une des villes les plus pittoresques de l'Inde.
          Visitez le célèbre City Palace, un complexe palatial qui surplombe le
          lac Pichola. Explorez les jardins de Saheliyon-ki-Bari, connus pour
          leurs fontaines et leurs kiosques élégants. Profitez d'une balade en
          bateau sur le lac Pichola et admirez les vues panoramiques sur les
          palais et les ghats. En soirée, assistez à un spectacle culturel de
          danse et de musique traditionnelle rajasthani.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bundi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Udaipur - Chittorgarh - Bundi</h2>
        <p>
          Quittez Udaipur et partez pour Chittorgarh, une ancienne capitale
          royale connue pour son impressionnante forteresse. Explorez les
          remparts, les palais et les temples de Chittorgarh, témoignages de son
          histoire glorieuse. Continuez votre voyage vers Bundi, une ville
          historique célèbre pour ses palais et ses stepwells magnifiquement
          sculptés. Profitez de l'atmosphère paisible de cette ville hors des
          sentiers battus.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bundi2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 12 : Bundi</h2>
        <p>
          Explorez les attractions de Bundi, notamment le fort de Bundi, qui
          offre une vue panoramique sur la ville. Visitez les palais ornés de
          fresques, tels que le palais de Chitrashala, qui présente des
          peintures murales détaillées. Explorez les stepwells de Bundi, des
          structures architecturales ingénieuses utilisées pour collecter l'eau.
          Profitez de l'ambiance médiévale de la vieille ville et découvrez
          l'artisanat local, notamment la broderie, la poterie et les peintures
          miniatures.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/ran.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 13 : Bundi à Ranthambore</h2>
        <p>
          Quittez Bundi et rendez-vous à Ranthambore, célèbre pour son parc
          national abritant des tigres sauvages. À votre arrivée, détendez-vous
          à votre hôtel et profitez de l'environnement naturel. Dans
          l'après-midi, partez pour un safari en jeep dans le parc national de
          Ranthambore pour observer la faune diverse, y compris les tigres, les
          léopards, les cerfs et les oiseaux.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jaipur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 14 : Ranthambore - Jaipur</h2>
        <p>
          Profitez d'un autre safari matinal à Ranthambore pour augmenter vos
          chances d'apercevoir des tigres. Après le safari, partez pour Jaipur,
          la capitale du Rajasthan. À votre arrivée à Jaipur, enregistrez-vous à
          votre hôtel et détendez-vous. Profitez de la soirée pour explorer les
          bazars animés et vous imprégner de l'ambiance royale de la "Ville
          rose".
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jaipur2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 15 : Jaipur</h2>
        <p>
          Explorez les attractions majeures de Jaipur, notamment le City Palace,
          le Hawa Mahal (Palais des Vents) et le Jantar Mantar (observatoire
          astronomique). Visitez le fort d'Amber, situé sur une colline, et
          profitez d'une balade à dos d'éléphant ou en jeep jusqu'à l'entrée du
          fort. Découvrez l'artisanat local, tels que les textiles, la
          maroquinerie et les bijoux, dans les bazars colorés de Jaipur.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/fatherpur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 16 : Jaipur - Abhaneri - Fatehpur Sikri - Agra</h2>
        <p>
          En route vers Agra, faites une halte à Abhaneri pour visiter le Chand
          Baori, un puits étonnant à plusieurs niveaux orné de sculptures.
          Continuez vers Fatehpur Sikri, une ancienne ville abandonnée célèbre
          pour son architecture mughale remarquable. À votre arrivée à Agra,
          visitez le magnifique Taj Mahal au coucher du soleil, un chef-d'œuvre
          architectural et un symbole d'amour éternel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/agra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 17 : Agra - Gwalior</h2>
        <p>
          Visitez le Taj Mahal tôt le matin pour profiter de sa beauté
          majestueuse dans la lumière matinale. Ensuite, explorez le fort
          d'Agra, un site historique imposant offrant une vue panoramique sur la
          ville. Quittez Agra et partez pour Gwalior, une ville riche en
          histoire et en architecture. À Gwalior, visitez le fort de Gwalior, un
          complexe massif datant du 8e siècle, et découvrez les magnifiques
          palais et temples à l'intérieur.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/gwalior.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 18 : Gwalior - Dettia - Orcha</h2>
        <p>
          Quittez Gwalior et partez pour Orchha. En route, faites une halte à
          Datia pour visiter le célèbre temple de Pitambara Peeth, un important
          lieu de pèlerinage hindou. À votre arrivée à Orchha, découvrez
          l'architecture médiévale de cette ville historique. Visitez le
          complexe du palais de Raja Mahal et Jahangir Mahal, ainsi que les
          temples de Orchha, tels que le temple de Ram Raja et le temple de
          Chaturbhuj.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/orchaa.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 19 : Orcha - Khajuraho</h2>
        <p>
          Départ d'Orchha pour Khajuraho, célèbre pour ses temples hindous et
          jaïns ornés de sculptures érotiques. À votre arrivée à Khajuraho,
          explorez les temples de l'ouest et de l'est, qui présentent une
          magnifique architecture et des sculptures détaillées. Plongez dans
          l'histoire et la spiritualité de ces temples et découvrez les
          intrications des sculptures.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/khajurao.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 20 : Khajuraho (visites / train de nuit pour Varanasi)</h2>
        <p>
          Passez la journée à Khajuraho pour explorer davantage les temples et
          vous immerger dans l'atmosphère sereine de la ville. Visitez le musée
          archéologique de Khajuraho pour en apprendre davantage sur l'histoire
          et la signification des sculptures. Profitez également du temps libre
          pour vous promener dans les ruelles de la ville et découvrir
          l'artisanat local, notamment les sculptures sur pierre et les
          textiles.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/varanasi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 21 : Khajuraho - Varanasi</h2>
        <p>
          Départ de Khajuraho pour Varanasi, l'une des plus anciennes villes
          vivantes du monde et un important lieu de pèlerinage pour les hindous.
          À votre arrivée à Varanasi, profitez du reste de la journée pour vous
          détendre ou explorer les rives sacrées du Gange. En soirée, assistez à
          la cérémonie Aarti, un rituel spirituel vibrant qui se déroule au bord
          du fleuve.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/sarnath.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 22 : Varanasi - Sarnath - Varanasi</h2>
        <p>
          Tôt le matin, faites une excursion à Sarnath, un lieu saint bouddhiste
          où Bouddha a donné son premier enseignement. Explorez les temples et
          les stupas de Sarnath, imprégnez-vous de la spiritualité de l'endroit
          et découvrez l'histoire bouddhiste. De retour à Varanasi, visitez les
          ghats et assistez à des rituels et à des cérémonies sur les rives du
          Gange. En fin de journée, transfert à l'aéroport de Varanasi pour
          votre vol de départ.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 22 : Varanasi à Delhi (départ le même jour pour la France)</h2>
        <p>Arrivée à Delhi et départ pour la France le même jour.</p>
      </div>
    </div>
  );
};

export default Delhi;
