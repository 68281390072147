import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GuideCardsCarousel = () => {
  const guideData = [
    {
      name: "Deepesh Jadoun",
      experience: new Date().getFullYear() - 2008,
      photo: "deepesh.jpg",
      description:
        "Deepesh Jadoun travaille comme guide depuis 2008 et se spécialise dans la réalisation de visites exceptionnelles de l'Inde pour des clients français. Avec plus de 13 ans d'expérience, il possède une connaissance approfondie de l'histoire, de la culture et des sites indiens. Deepesh s'attache à créer des expériences mémorables et à assurer la plus grande satisfaction de ses clients lors de leurs voyages en Inde.",
    },

    {
      name: "Kapil Mittal",
      experience: new Date().getFullYear() - 2008,
      photo: "kapil.jpg",
      description:
        "Kapil Mittal est un guide passionné qui a plus de 13 ans d'expérience dans l'organisation de voyages à travers l'Inde. Depuis 2008, il partage avec les visiteurs son expertise et son amour de l'histoire, de l'architecture et des traditions culturelles indiennes. Les récits captivants de Kapil, associés à sa connaissance approfondie des différentes régions du pays, permettent aux voyageurs de plonger dans la vibrante tapisserie du passé et du présent de l'Inde.",
    },
    {
      name: "Nitin Raj",
      experience: 12,
      photo: "nitinraj.jpg", // Remplacez ceci par le nom réel du fichier de la photo de Nitin Raj
      description:
        "Nitin Raj est un expert du voyage et un guru de yoga expérimenté avec 12 ans d'expérience. Passionné à la fois par les voyages et le yoga, il guide les voyageurs lors de périples enrichissants à travers l'Inde, offrant des perspectives uniques sur le patrimoine culturel du pays et les pratiques spirituelles. L'expertise de Nitin dans les expériences de voyage en anglais et dans le yoga fait de lui un guide précieux pour ceux qui recherchent des aventures de voyage holistiques et revitalisantes.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    centerPadding: "1%",
  };

  return (
    <div className="bg-gray-100 py-10">
      <h1 className="text-4xl font-bold text-center mb-8">
        Voyage en Inde avec nos guides professionnels
      </h1>
      <div className="mx-auto w-4/5 md:w-3/5">
        <Slider {...settings}>
          {guideData.map((guide, index) => (
            <div key={index} className="px-4">
              <div className="bg-white shadow-lg rounded-lg p-6 h-full">
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4">
                  <img
                    src={guide.photo}
                    alt={`Guide ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
                {/* Guide card content as before... */}
                <h2 className="text-xl font-semibold mb-2">{guide.name}</h2>
                <p className="text-gray-400 mb-1">
                  Travaillant depuis {guide.experience} ans, s'occupant de
                  clients français en tournée en Inde
                </p>
                <p className="text-gray-600 text-justify">
                  {guide.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="mt-10 mx-auto w-4/5 md:w-3/5">
        <h2 className="text-3xl font-bold mb-4">
          Services de guides locaux en Inde
        </h2>
        <p className="text-gray-600 text-justify">
          nous sommes des agences de voyages en inde en plus de nos guides
          professionnels, nous proposons également les services de guides locaux
          dans certaines villes telles que Jaipur, Agra, Delhi, Udaipur,
          Jodhpur, Varanasi, Kochi, Pondichéry et d'autres. Ces guides locaux
          sont à la disposition des voyageurs qui souhaitent vivre une
          expérience de courte durée, généralement d'un à deux jours, dans ces
          villes. Ils vous feront découvrir les points forts, les trésors cachés
          et l'essence de chaque ville.
        </p>
      </div>
      <div className="mt-10 mx-auto w-4/5 md:w-3/5">
        <h2 className="text-3xl font-bold mb-4">
          Services de transport en inde et d'hôtel
        </h2>
        <p className="text-gray-600 text-justify">
          pour votre voyage en inde Nous proposons également des services de
          véhicules de tourisme, avec d'excellentes connexions pour desservir
          des circuits entiers ou ou des villes individuelles. Notre flotte
          comprend voitures disponibles pour un circuit complet ou une location
          à la journée. location à la journée. Nous desservons les principales
          villes telles que Udaipur, Jaipur, Agra, Delhi, Varanasi, Pondichéry
          et bien d'autres, Jaipur, Agra, Delhi, Varanasi, Pondichéry et bien
          d'autres. Nos chauffeurs chauffeurs sont expérimentés et parlent
          couramment l'anglais. À nous facilitons les réservations d'hôtel et
          entretenons des partenariats avec des hôtels de premier plan.
          partenariats avec d'importants hôtels du patrimoine, des
          établissements cinq étoiles renommés et des villas luxueuses. cinq
          étoiles et des villas luxueuses.
        </p>
      </div>
    </div>
  );
};

export default GuideCardsCarousel;
