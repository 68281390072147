import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const SouthIndia = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Circuit de l'Inde du Sud</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/chennai.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 1 : Chennai</h2>
        <p>
          Après votre arrivée à Chennai, prenez le temps de vous installer et de
          vous acclimater à la ville en explorant ses attractions culturelles et
          historiques telles que le Fort St. George, le Musée du gouvernement et
          la plage de Marina.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kanchipuram.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 2 : Kanchipuram - Mahabalipuram</h2>
        <p>
          Partez tôt le matin pour Kanchipuram, la "ville aux mille temples", et
          visitez des temples renommés tels que le temple Ekambareswarar et le
          temple Kailasanathar. Ensuite, dirigez-vous vers Mahabalipuram pour
          admirer les temples rupestres, les bas-reliefs spectaculaires et la
          célèbre plage de Mahabalipuram.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mahabalipuram.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 3 : Mahabalipuram</h2>
        <p>
          Explorez davantage Mahabalipuram en visitant des sites emblématiques
          tels que le temple du Rivage, le temple du Rivage des Tigres, et les
          célèbres bas-reliefs d'Arjuna's Penance. Profitez également de la
          plage et de la cuisine locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/pondicherry.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 4 : Mahabalipuram - Pondichéry</h2>
        <p>
          Voyagez vers Pondichéry, ancienne colonie française, pour explorer le
          quartier français avec son charme colonial, visiter l'Ashram de Sri
          Aurobindo et l'établissement expérimental d'Auroville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/chidambaram.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >
          Jour 5 : Pondichéry - Chidambaram - Kumbakonam - Darasuram - Thanjavur
        </h2>
        <p>
          Visitez le temple de Nataraja à Chidambaram, célèbre pour son
          architecture et sa dévotion à Shiva. Ensuite, dirigez-vous vers
          Kumbakonam, connue pour ses temples et ses étangs sacrés, et visitez
          le temple de Kumbeshwara. Faites un arrêt à Darasuram pour admirer le
          temple Airavatesvara, un chef-d'œuvre de l'architecture chola.
          Terminez la journée à Thanjavur en explorant le temple de
          Brihadesvara, un site du patrimoine mondial de l'UNESCO.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/tanjor.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 6 : Thanjavur - Trichy</h2>
        <p>
          Profitez de la matinée pour explorer Thanjavur et visiter le palais de
          Thanjavur et le musée d'art. Ensuite, dirigez-vous vers Trichy et
          visitez le célèbre temple de Srirangam, l'un des plus grands complexes
          de temples hindous au monde. Continuez avec une visite du fort de
          Trichy et de son temple de Rockfort pour profiter de vues panoramiques
          sur la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trichy.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 7 : Trichy - Chettinad</h2>
        <p>
          Voyagez jusqu'à la région de Chettinad, réputée pour son architecture
          unique et ses délices culinaires. Explorez les impressionnantes
          demeures de Chettinad, connues sous le nom de "Chettinad Mansions", et
          découvrez l'histoire et la culture de cette région prospère.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/chettinad.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 8: Chettinad - Madurai</h2>
        <p>
          Continuez votre voyage vers Madurai, où vous visiterez le temple de
          Meenakshi Amman, un joyau architectural et un important lieu de
          pèlerinage. Explorez le marché animé de Madurai et découvrez
          l'ambiance colorée de la ville.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/madurai.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 9 : Madurai - Periyar</h2>
        <p>
          Voyagez jusqu'à Periyar, une réserve naturelle réputée pour sa faune
          et sa flore abondantes. Profitez d'une croisière en bateau sur le lac
          Periyar pour observer les animaux sauvages tels que les éléphants, les
          tigres et les oiseaux exotiques.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/periyar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 10 : Periyar - Kumarakom</h2>
        <p>
          Dirigez-vous vers Kumarakom, situé au bord du lac Vembanad. Profitez
          d'une croisière en péniche traditionnelle dans les backwaters,
          explorez les villages pittoresques et admirez les magnifiques
          paysages.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kumarkaon.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 11 : Kumarakom - Cochin</h2>
        <p>
          Partez pour Cochin (Kochi), une ville historique et cosmopolite.
          Visitez le quartier de Fort Kochi avec ses influences coloniales,
          assistez à un spectacle de Kathakali, une danse traditionnelle du
          Kerala, et explorez les filets de pêche chinois emblématiques.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/kochin.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 12 : Cochin - Coonoor</h2>
        <p>
          Voyagez jusqu'à Coonoor, une charmante ville nichée dans les montagnes
          des Nilgiris. Profitez du trajet en train panoramique jusqu'à Coonoor,
          visitez les plantations de thé et découvrez les magnifiques paysages
          de collines verdoyantes.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/conoor.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 13 : Coonoor - Ooty</h2>
        <p>
          Poursuivez votre voyage vers Ooty (Udhagamandalam), une célèbre
          station de montagne. Explorez les jardins botaniques, faites un tour
          en train miniature à vapeur et profitez de la beauté naturelle
          environnante.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/ooty.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 14 : Ooty - Mysore</h2>
        <p>
          Voyagez jusqu'à Mysore, une ville royale réputée pour son palais
          somptueux, le palais de Mysore. Visitez également le temple
          Chamundeshwari, les jardins de Brindavan et découvrez l'artisanat
          local.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mysore.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 15 : Mysore </h2>
        <p>
          Continuez à explorer Mysore en visitant le marché coloré Devaraja, le
          musée du palais de Jaganmohan Art et la colline de Chamundi pour
          profiter d'une vue panoramique sur la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/hassan.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 16 : Hassan - Belur - Halebid - Hassan</h2>
        <p>
          Voyagez jusqu'à Hassan et visitez les temples de Belur et Halebid,
          célèbres pour leur architecture Hoysala exquise.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/hospet.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 17 : Hassan - Hospet</h2>
        <p>
          Continuez votre voyage jusqu'à Hospet, la porte d'entrée de Hampi, un
          site du patrimoine mondial de l'UNESCO. Explorez les ruines antiques
          de la civilisation de Vijayanagara, y compris le temple de Virupaksha
          et le palais des rois.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/hampi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 18 : Hospet - Hampi - Hospet</h2>
        <p>
          Poursuivez votre exploration de Hampi en visitant les temples et les
          structures historiques, y compris le Lotus Mahal, le temple
          d'Achyutaraya et les ruines royales.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/badami.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 19 : Hampi - Badami</h2>
        <p>
          Voyagez jusqu'à Badami, une ville connue pour ses temples rupestres.
          Visitez les célèbres temples de Badami, les grottes d'Aihole et
          Pattadakal, tous témoins de l'architecture et de l'histoire anciennes.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/goa.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 20 : Badami - Goa</h2>
        <p>
          Terminez votre voyagepar un voyage jusqu'à Goa, une destination
          balnéaire prisée. Profitez des plages magnifiques, de l'atmosphère
          détendue et découvrez la culture vibrante de Goa. Après avoir exploré
          Goa, préparez-vous à votre départ pour la France.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 >Jour 21 : Goa - FRANCE</h2>
        <p>
          Temps libre à Goa pour profiter de la plage, vous détendre ou faire du
          shopping, Transfert à l'aéroport de Goa pour votre vol de retour en
          France.
        </p>
      </div>
    </div>
  );
};

export default SouthIndia;
