import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Himanchal3 = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Trekking dans la vallée de l'Himalaya</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Trekking dans l'Himanchal</h2>
        <p>
          Départ de Delhi et voyage jusqu'à Ropa, point de départ de votre
          trekking. Prenez le temps de vous installer et de vous familiariser
          avec l'équipe de trekking.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek1.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Ropa (Bijal)</h2>
        <p>Altitude : Environ 2 700 mètres</p>
        <p>Distance de randonnée : Environ 10 kilomètres</p>
        <p>Temps de randonnée : Environ 5 à 6 heures</p>{" "}
        <p>
          Commencez votre trek depuis Ropa en direction de Bijal. Marchez à
          travers des paysages pittoresques, des vallées verdoyantes et des
          sentiers montagneux. Campez à Bijal pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Ropa - Niharni - Barshan</h2>
        <p>Altitude : Variable, principalement autour de 2 800 mètres</p>
        <p>Distance de randonnée : Environ 15 kilomètres</p>
        <p>Temps de randonnée : Environ 7 à 8 heures</p>
        <p>
          Poursuivez votre trek de Bijal à Niharni, puis à Barshan. Profitez de
          la marche à travers les villages locaux, découvrez la culture et les
          traditions des habitants de montagne. Installez votre campement à
          Barshan pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek3.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Barshan - Village Shakti</h2>
        <p>Altitude : Environ 2 600 mètres</p>
        <p>Distance de randonnée : Environ 12 kilomètres</p>
        <p>Temps de randonnée : Environ 6 à 7 heures</p>
        <p>
          Continuez votre trek de Barshan au village de Shakti. Traversez des
          paysages magnifiques, des rivières et des ponts suspendus.
          Imprégnez-vous de l'atmosphère paisible du village et passez la nuit
          en camping.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek4.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Village Shakti - Marod</h2>
        <p>Altitude : Environ 2 900 mètres</p>
        <p>Distance de randonnée : Environ 15 kilomètres</p>
        <p>Temps de randonnée : Environ 7 à 8 heures</p>
        <p>
          Poursuivez votre trek de Village Shakti à Marod. Marchez à travers des
          sentiers sinueux et admirez les panoramas spectaculaires des montagnes
          de l'Himalaya. Installez votre camp à Marod pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek5.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Marod - Cheushkhol</h2>
        <p>Altitude : Environ 3 200 mètres</p>
        <p>Distance de randonnée : Environ 14 kilomètres</p>
        <p>Temps de randonnée : Environ 6 à 7 heures</p>{" "}
        <p>
          Continuez votre trek de Marod à Cheushkhol. Traversez des forêts
          luxuriantes et des villages pittoresques en chemin. Profitez de la
          nature environnante et campez à Cheushkhol pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek7.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Cheushkhol - Raktikhol (via Ajmer)</h2>
        <p>Altitude : Environ 3 800 mètres</p>
        <p>Distance de randonnée : Environ 12 kilomètres</p>
        <p>Temps de randonnée : Environ 5 à 6 heures</p>
        <p>
          Poursuivez votre trek de Cheushkhol à Raktikhol en passant par Ajmer.
          Marchez à travers des sentiers escarpés et des terrains variés.
          Admirez la beauté naturelle de la région et installez votre camp à
          Raktikhol pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek8.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Raktikhol - Raktisour</h2>
        <p>Altitude : Environ 4 500 mètres</p>
        <p>Distance de randonnée : Environ 10 kilomètres</p>
        <p>Temps de randonnée : Environ 5 à 6 heures</p>{" "}
        <p>
          Continuez votre trek de Raktikhol à Raktisour. Traversez des cols de
          montagne et profitez de vues panoramiques sur les sommets enneigés de
          l'Himalaya. Établissez votre camp à Raktisour pour la nuit.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek10.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Raktisour - Prakachi</h2>
        <p>AAltitude : Variable, principalement autour de 3 800 mètres</p>
        <p>Distance de randonnée : Environ 15 kilomètres</p>
        <p>Temps de randonnée : Environ 7 à 8 heures</p>{" "}
        <p>
          Poursuivez votre trek de Raktisour à Prakachi. Marchez à travers des
          vallées verdoyantes et des paysages spectaculaires. Découvrez la faune
          et la flore locales et campez à Prakachi pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek11.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Prakachi - Marod</h2>
        <p>Altitude : Variable, principalement autour de 2 900 mètres</p>
        <p>Distance de randonnée : Environ 18 kilomètres</p>
        <p>Temps de randonnée : Environ 8 à 9 heures</p>{" "}
        <p>
          Revenez sur vos pas de Prakachi à Marod. Descendez lentement en
          altitude tout en profitant des dernières vues sur les montagnes de
          l'Himalaya. Installez votre camp à Marod pour la nuit.{" "}
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek12.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Village Marod - Village Shughad</h2>
        <p>Altitude : Variable, principalement autour de 2 800 mètres</p>
        <p>Distance de randonnée : Environ 14 kilomètres</p>
        <p>Temps de randonnée : Environ 6 à 7 heures</p>
        <p>
          Continuez votre trek du village de Marod au village de Shughad.
          Traversez des villages traditionnels et rencontrez les habitants
          locaux. Imprégnez-vous de la culture locale et passez la nuit en
          camping au village de Shughad.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek13.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 12 : Village Shughad - Daingapul</h2>
        <p>Altitude : Variable, principalement autour de 2 700 mètres</p>
        <p>Distance de randonnée : Environ 12 kilomètres</p>
        <p>Temps de randonnée : Environ 5 à 6 heures</p>
        <p>
          Poursuivez votre trek du village de Shughad à Daingapul. Marchez à
          travers des paysages pittoresques, des rizières en terrasses et des
          vallées verdoyantes. Profitez de la tranquillité de la nature et
          campez à Daingapul pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek6.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 13 : Daingapul - Niharni - Ropa</h2>
        <p>Altitude : Variable, principalement autour de 2 700 mètres</p>
        <p>Distance de randonnée : Environ 14 kilomètres</p>
        <p>Temps de randonnée : Environ 6 à 7 heures</p>
        <p>
          Continuez votre trek de Daingapul à Niharni, puis retournez à Ropa.
          Profitez des dernières étapes de votre trek et prenez le temps de vous
          imprégner de la beauté de la région. Retournez à Ropa et campez pour
          la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek14.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 14 : Ropa - Delhi</h2>
        <p>
          Terminez votre trek en retournant de Ropa à Delhi. Profitez du trajet
          de retour pour vous reposer, échanger des anecdotes et des souvenirs
          avec vos compagnons de trek, et réfléchir à votre aventure incroyable
          dans l'Himalaya.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 15 : Delhi (Départ)</h2>
        <p>
          Profitez de votre dernière journée à Delhi pour explorer la ville,
          faire du shopping ou vous détendre avant votre vol de retour.
          Transfert à l'aéroport de Delhi pour votre départ, emportant avec vous
          des souvenirs inoubliables de votre expérience de trekking dans
          l'Himalaya.
        </p>
      </div>
    </div>
  );
};

export default Himanchal3;
