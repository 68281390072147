import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Himanchal2 = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Trekking dans la nature</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          À votre arrivée à l'aéroport de Delhi, vous serez accueilli et
          transféré à votre hôtel. Profitez de la journée pour vous reposer,
          vous remettre du voyage et vous préparer pour votre aventure de
          trekking à venir.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek4.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi à Bijal</h2>
        <p>
          Quittez Delhi et prenez un moyen de transport jusqu'à Bijal, point de
          départ de votre trek. Installez-vous au camp de base,
          familiarisez-vous avec l'équipe de trekking et les guides locaux, et
          préparez votre équipement pour le trek.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek5.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 3 : Bijal à Sukhasour (2400 mètres) - Randonnée de 10 à 12 km
        </h2>
        <p>
          Commencez votre trek de Bijal à Sukhasour, situé à une altitude de
          2400 mètres. Randonnée sur une distance d'environ 10 à 12 km, à
          travers des paysages pittoresques et des sentiers montagneux. Profitez
          de vues panoramiques et de la beauté naturelle qui vous entoure.
          Campez à Sukhasour pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek7.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Sukhasour à Shilthati - Randonnée de 10 à 14 km</h2>
        <p>
          Continuez votre trek de Sukhasour à Shilthati, une étape d'environ 10
          à 14 km. Traversez des vallées verdoyantes, des ruisseaux et des
          prairies alpines. Profitez de la marche et prenez le temps d'admirer
          les panoramas magnifiques. Établissez votre camp à Shilthati pour la
          nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek11.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Shilthati à Mahamai Temple Thini (3100 mètres)</h2>
        <p>
          Poursuivez votre trek de Shilthati à Mahamai Temple Thini, situé à une
          altitude de 3100 mètres. Randonnée sur une distance qui peut varier en
          fonction des conditions et de l'itinéraire choisi. Explorez les
          sentiers montagneux et atteignez le Mahamai Temple, un lieu sacré de
          la région. Campez à Mahamai Temple Thini pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek8.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Mahamai Thini à Bijal</h2>
        <p>
          Revenez sur vos pas de Mahamai Temple Thini à Bijal, en profitant des
          paysages en descendant lentement en altitude. Profitez des dernières
          vues sur les montagnes majestueuses et réfléchissez à votre expérience
          de trekking dans l'Himalaya. Une fois arrivé à Bijal, installez votre
          camp pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/trek10.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Bijal à Delhi</h2>
        <p>
          Terminez votre trek en retournant de Bijal à Delhi. Profitez du trajet
          de retour pour vous reposer, échanger des anecdotes et des souvenirs
          avec vos compagnons de trek, et réfléchir à votre aventure inoubliable
          dans les montagnes himalayennes.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Delhi (Départ)</h2>
        <p>
          Profitez de votre dernière journée à Delhi pour explorer la ville,
          faire du shopping ou vous détendre avant votre vol de retour.
          Transfert à l'aéroport de Delhi pour votre départ, emportant avec vous
          des souvenirs inoubliables de votre trekking dans l'Himalaya.
        </p>{" "}
      </div>
    </div>
  );
};

export default Himanchal2;
