import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Maharaja = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Circuit du Rajasthan</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi, Inde</h2>
        <p>
          À votre arrivée à l'aéroport de Delhi, vous serez accueilli et
          transféré à votre hôtel. Prenez le temps de vous reposer et vous
          acclimater à la ville animée de Delhi.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/shekawati.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi/Shekhawati</h2>
        <p>
          Après le petit-déjeuner, partez de Delhi et rendez-vous à Shekhawati.
          Explorez la région de Shekhawati, réputée pour ses magnifiques
          fresques et ses havelis richement décorés. Imprégnez-vous de
          l'histoire et de la culture de cette région fascinante.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bikaner.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Shekhawati/Bikaner</h2>
        <p>
          Continuez votre voyage vers Bikaner, une ville ancienne connue pour
          son impressionnante architecture et son fort bien préservé. Visitez le
          fort de Junagarh et découvrez la richesse culturelle de la région.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jaisalmer.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Bikaner/Jaisalmer</h2>
        <p>
          De Bikaner, partez pour Jaisalmer, la "ville dorée". Explorez la
          forteresse de Jaisalmer, les temples magnifiques et les ruelles
          étroites remplies de maisons en grès doré. Imprégnez-vous de
          l'ambiance unique de cette ville du désert.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jaisalmer2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Visite de Jaisalmer</h2>
        <p>
          Profitez d'une journée complète pour explorer Jaisalmer plus en
          profondeur. Visitez les havelis ornés, le lac Gadisar, les temples
          jaïns et découvrez l'artisanat local. Assurez-vous de profiter du
          magnifique coucher de soleil sur les dunes de sable.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jodhpur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Jaisalmer/Jodhpur</h2>
        <p>
          Quittez Jaisalmer et dirigez-vous vers Jodhpur, la "ville bleue".
          Visitez le majestueux fort de Mehrangarh, explorez les ruelles animées
          de la vieille ville et découvrez l'histoire fascinante de cette ville
          fortifiée.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jodhpur2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Jodhpur/Jaipur (via Ajmer)</h2>
        <p>
          De Jodhpur, partez pour Jaipur, la "ville rose". En route, faites un
          arrêt à Ajmer pour visiter le célèbre sanctuaire d'Ajmer Sharif. À
          Jaipur, plongez dans la grandeur des palais, des forts et des bazars
          colorés.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jaipur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Visite de Jaipur</h2>
        <p>
          Profitez d'une journée complète pour découvrir les attractions
          emblématiques de Jaipur, telles que le palais des vents (Hawa Mahal),
          le fort d'Amber, le palais de la ville et l'observatoire astronomique
          de Jantar Mantar. Explorez les bazars animés et goûtez à la cuisine
          locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/fatherpur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Jaipur/Fatehpur Sikri/Agra</h2>
        <p>
          Quittez Jaipur et dirigez-vous vers Agra. En chemin, faites un arrêt à
          Fatehpur Sikri, une ancienne ville fantôme. À Agra, visitez le célèbre
          Taj Mahal, le fort d'Agra et d'autres sites historiques importants.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/agra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Agra/Delhi</h2>
        <p>
          Commencez votre journée par une visite du magnifique Taj Mahal au
          lever du soleil, Explorez le fort d'Agra et le tombeau
          d'Itimad-ud-Daulah. Ensuite, quittez Agra et retournez à Delhi, Nuitée
          à Delhi
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Départ de Delhi</h2>
        <p>
          Après le petit-déjeuner, profitez de votre dernière journée à Delhi
          pour explorer la ville, faire du shopping ou vous détendre avant votre
          vol de départ. Transfert à l'aéroport de Delhi pour votre vol de
          retour, emportant avec vous des souvenirs inoubliables de votre voyage
          en Inde.
        </p>
      </div>
    </div>
  );
};

export default Maharaja;
