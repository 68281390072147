import React from "react";

const OwnerTile = () => {
  return (
    <div className="bg-gray-200 shadow-lg rounded-lg p-4 ml-4 mr-4 mb-4  flex flex-col items-center">
      <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
        <img
          src="/owner.jpg"
          alt="Owner"
          className="w-full h-full object-cover"
        />
      </div>
      <h2 className="text-xl font-semibold mb-2">Bhupesh Sharma</h2>
      <p className="text-gray-600 mb-2">Directeur</p>
      <p className="text-gray-600 text-justify">
        Bhupesh Sharma travaille dans l'industrie du voyage depuis 2008. Avec
        une expérience approfondie dans la gestion des clients, il a joué un
        rôle essentiel dans la fourniture d'expériences de voyage
        exceptionnelles à nos clients. L'expertise et le dévouement de Bhupesh
        ont été déterminants dans le succès de notre entreprise, et il continue
        de guider notre équipe vers de nouveaux sommets.
      </p>
    </div>
  );
};

export default OwnerTile;
