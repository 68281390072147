import React from "react";
import { Link } from "react-router-dom";

const CustomToursComponent = () => {
  const toursData = [
    {
      image: "/tours/delhi.jpg",
      name: "Circuit de Triangle d'or",
      link: "goldentriangle",
      days: "7 Jours",
      description:
        "Le circuit du Triangle d'Or est l'un des itinéraires les plus populaires et les plus emblématiques de l'Inde, englobant trois villes enchanteresses : Delhi, Agra et Jaipur. Ce voyage captivant offre un aperçu unique de la riche histoire de l'Inde, de sa diversité culturelle et de ses merveilles architecturales.",
    },
    {
      image: "/tours/trek2.jpg",
      name: "Trekking dans la vallée de l'Himalaya",
      link: "himanchal3",
      days: "15 Jours",
      description:
        "Embarquez pour un trekking exaltant dans l'Himalaya et immergez-vous dans la beauté époustouflante de la chaîne de montagnes la plus majestueuse du monde. Ce voyage plein d'aventures vous fera découvrir des paysages époustouflants, des vallées vierges et des villages anciens nichés au milieu des pics enneigés.",
    },
    {
      image: "/tours/periyar.jpg",
      name: "Circuit de l'Inde du Sud",
      link: "southindia",
      days: "21 Jours",
      description:
        "Embarquez pour un voyage enchanteur dans le sud de l'Inde et plongez dans la riche tapisserie de sa culture, de son histoire et de sa beauté naturelle.Ce circuit captivant vous emmène à travers un paysage varié orné d'une végétation luxuriante, de temples majestueux, de plages immaculées et de villes animées.",
    },
    {
      image: "/tours/jaisalmer.jpg",
      name: "Circuit du Rajasthan",
      link: "maharaja",
      days: "11 Jours",
      description:
        "Embarquez pour une odyssée royale à travers les terres enchanteresses du Rajasthan, où l'histoire, le courage et l'opulence s'unissent pour créer une tapisserie envoûtante.Ce circuit captivant vous emmène à travers les forts majestueux, les palais élégants et les bazars animés qui incarnent l'essence de la 'Terre des rois'.",
    },
    {
      image: "/tours/patan.jpg",
      name: "Circuit du Gujrat",
      link: "gujarat",
      days: "21 Jours",
      description:
        "Embark on a captivating journey to Gujarat, a land of vibrant colors, rich heritage, and diverse landscapes. This enchanting tour takes you through a kaleidoscope of experiences, from ancient historical sites to breathtaking natural wonders.",
    },
    {
      image: "/tours/jispa.jpg",
      name: "Circuit de Leh Laddak",
      link: "ladakh",
      days: "14 Jours",
      description:
        "Embarquez pour un voyage extraordinaire dans le pays enchanteur du Ladakh, souvent appelé le 'pays des hauts cols'.Nichée au cœur de l'imposant Himalaya, cette région reculée de l'extrême nord de l'Inde est un havre de paix pour les amateurs d'aventure, de spiritualité et de nature.",
    },
  ];

  return (
    <div className="container py-12">
      <h1 className="text-4xl font-bold text-center mb-8">
        Circuits et forfaits vacances sur mesure en Inde
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {toursData.map((tour, index) => (
          <Link to={`/destinations/${tour.link}`} key={index}>
            <div className="bg-white shadow-md rounded-lg p-4 transition duration-500 ease-in-out transform hover:scale-105 cursor-pointer flex flex-col h-full">
              <img
                src={tour.image}
                alt={`Tour ${index + 1}`}
                className="w-full h-40 object-cover rounded-t-lg"
              />
              <h2 className="text-xl font-semibold mt-2">{tour.name}</h2>
              <p className="text-gray-600 mt-1">{tour.days}</p>
              <p className="text-gray-600 mt-2 text-justify">
                {tour.description}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CustomToursComponent;
