import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Orissa2 = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Visite de la tribu d'Odhisa</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bhubaneswar2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Bhubaneswar - Dhauli - Konark - Puri</h2>
        <p>
          {" "}
          Commencez votre voyage en visitant Bhubaneswar, la capitale de l'État
          d'Odisha, explorez les temples emblématiques tels que le temple
          Lingaraj et le temple Mukteshwar, rendez-vous à Dhauli pour visiter le
          site bouddhiste, continuez vers Konark pour admirer le magnifique
          temple du Soleil, classé au patrimoine mondial de l'UNESCO, et
          terminez la journée à Puri, où vous pourrez découvrir le célèbre
          temple de Jagannath et profiter de la plage.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/chilika.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Puri - Chilika (Satapada) - Puri</h2>
        <p>
          Partez pour une excursion à Chilika, le plus grand lac salé d'Asie, à
          Satapada, embarquez dans un bateau pour observer les dauphins
          Irrawaddy et les oiseaux migrateurs, explorez les îles pittoresques,
          puis retournez à Puri pour profiter de son ambiance spirituelle et de
          sa belle plage.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/puri.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Puri - Raghurajpur - Sakhigopal - Bhubaneswar</h2>
        <p>
          Visitez le village artisanal de Raghurajpur, réputé pour ses peintures
          traditionnelles, continuez vers Sakhigopal pour visiter le temple de
          Sakhigopal dédié à Krishna, et terminez la journée à Bhubaneswar où
          vous pourrez explorer davantage les temples et l'histoire de la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/udaigiri.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 4 : Bhubaneswar - Jajpur - Ratnagiri - Umy-4 p-4 bg-white
          rounded-lg shadowagiri - Lalitgiri - Bhubaneswar
        </h2>
        <p>
          Partez tôt le matin pour Jajpur, une ville historique, où vous pourrez
          visiter le temple de Biraja Kshetra et le temple de Chandikhole,
          continuez vers Ratnagiri pour découvrir les vestiges bouddhistes et
          les sculptures, explorez les sites bouddhistes d'Umy-4 p-4 bg-white
          rounded-lg shadowagiri et de Lalitgiri, puis retournez à Bhubaneswar
          pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Visite de Bhubaneswar - Départ</h2>
        <p>
          Profitez de la matinée pour visiter les attractions restantes de
          Bhubaneswar, telles que le temple Rajarani, le temple Kedar Gouri et
          le musée d'État d'Odisha, imprégnez-vous de l'atmosphère spirituelle
          de la ville, puis préparez-vous pour votre départ selon votre
          programme de voyage.
        </p>
      </div>
    </div>
  );
};

export default Orissa2;
