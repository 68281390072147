import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 py-8">
      <div className="container mx-auto p-4">
        {/* Top section with company name, logo, email, and phone number */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-6">
          <div className="md:mr-6 mb-4 md:mb-0">
            <img src="/logo.png" alt="Company Logo" className="h-12 " />
          </div>
          <div className="text-white text-center md:text-left">
            <p className="text-lg font-semibold">
              Guide Francophone en Inde Namaste
            </p>
            <div className="bg-gray-800 p-2">
              <p>
                Adresse électronique: :
                services@guidefrancophoneenindenamaste.com
              </p>
              <p>téléphone: : +91-9267320793</p>
            </div>
          </div>
        </div>

        {/* Three rows */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 m-4">
          {/* First row */}
          <div className="text-white">
            <p className="font-semibold mb-4">À propos de l'entreprise</p>
            <ul className="bg-gray-800 p-2">
              <li>
                <a href="/about">À propos de nous</a>
              </li>
              <li>
                <a href="/contact">Contactez nous</a>
              </li>
            </ul>
          </div>

          {/* Second row */}
          <div className="text-white">
            <p className="font-semibold mb-4">Popular Destinations</p>
            <ul className="bg-gray-800 p-2">
              <li>
                <a href="/destinations/goldentriangle">
                  Circuit de Triangle d'or
                </a>
              </li>
              <li>
                <a href="/destinations/himanchal3">
                  Trekking dans la vallée de l'Himalaya
                </a>
              </li>
              <li>
                <a href="/destinations/southindia">Circuit de l'Inde du Sud</a>
              </li>
            </ul>
          </div>

          {/* Third row */}
          <div className="text-white">
            <p className="font-semibold mb-4">Suivez-nous</p>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=100093406325286"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-white" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=919267320793&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="text-white" />
              </a>
              <a
                href="https://www.instagram.com/guidefrancophoneenindenamaste/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-white" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCezhhLKV7MKzPamRb4O6PxA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="text-white" />
              </a>
              <a
                href="https://twitter.com/guideindefr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="text-white" />
              </a>
            </div>

            <div className="text-white bg-white p-2 m-2 flex justify-center">
              <div className="w-40">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                <div id="TA_cdsratingsonlynarrow485" class="TA_cdsratingsonlynarrow">
                  <ul id="Sq6cBByqJixL" class="TA_links fE09IQJ">
                    <li id="7uzfkwrXet" class="lVP5SXk">
                      <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g304551-d26142604-Reviews-Guide_Francophone_en_Inde_Namaste-New_Delhi_National_Capital_Territory_of_Delhi.html">
                        <img src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor"/>
                      </a>
                    </li>
                  </ul>
                </div>
                <script async src="https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&amp;uniq=485&amp;locationId=26142604&amp;lang=en_US&amp;border=true&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
              `,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Copyright notice */}
        <div className="mt-4 text-center text-white">
          &copy; 2023 Guide Francophone En Inde Namaste. Tous droits réservés.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
