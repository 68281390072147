import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Spiritual = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Tournée spirituelle dans la vallée du Gange</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          Après votre arrivée à Delhi, vous pourrez commencer votre voyage en
          explorant les attractions emblématiques de la ville, telles que le
          Fort Rouge, le Qutub Minar et le temple du Lotus. Imprégnez-vous de
          l'histoire et de la culture de la capitale indienne.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mathura2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi à Mathura</h2>
        <p>
          Partez de Delhi pour vous rendre à Mathura, la ville sainte associée à
          la naissance de Lord Krishna. Visitez le temple de Krishna
          Janmabhoomi, le temple de Dwarkadhish et découvrez l'atmosphère
          spirituelle de la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/mathura.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Mathura - Vrindavan - Barsana - Mathura</h2>
        <p>
          Faites une excursion à Vrindavan, où vous pourrez visiter les célèbres
          temples de Radha Raman, de Banke Bihari et d'Iskcon. Ensuite,
          dirigez-vous vers Barsana, le lieu de naissance de Radha, pour
          découvrir ses temples et participer aux célébrations colorées du
          festival de Holi (selon la période de l'année).
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/lucknow.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Mathura à Lucknow</h2>
        <p>
          Voyagez de Mathura à Lucknow, la capitale de l'État de l'Uttar
          Pradesh. Explorez les monuments historiques tels que le Rumi Darwaza,
          le Bhool Bhulaiya et le palais de Bara Imambara. Dégustez également la
          célèbre cuisine de Lucknow, réputée pour ses délices culinaires.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/agra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Lucknow à Agra</h2>
        <p>
          Partez de Lucknow pour vous rendre à Agra et visitez le joyau de
          l'Inde, le Taj Mahal, un monument emblématique du pays. Explorez
          également le fort d'Agra et d'autres sites historiques de la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/haridwar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Agra - Haridwar - Rishikesh</h2>
        <p>
          Voyagez d'Agra à Haridwar, une ville sacrée située sur les rives du
          Gange. Profitez des rituels du soir à Har Ki Pauri, un ghat célèbre,
          puis dirigez-vous vers Rishikesh, la capitale mondiale du yoga.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/rishikeh.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Rishikesh</h2>
        <p>
          Passez la journée à Rishikesh en explorant ses ashrams, ses temples et
          en vous adonnant à des activités spirituelles telles que la
          méditation, le yoga et la participation à la cérémonie du Ganga Aarti
          au bord du fleuve Gange.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Rishikesh à Delhi (départ)</h2>
        <p>
          Revenez à Delhi depuis Rishikesh pour votre départ. Profitez d'un
          dernier jour pour explorer les marchés locaux, faire du shopping et
          savourer les délices culinaires avant de quitter l'Inde.
        </p>
      </div>
    </div>
  );
};

export default Spiritual;
