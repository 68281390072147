import React, { useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdPhone, MdEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const phoneNumber = "+91-9267320793";
  const email = "services@guidefrancophoneenindenamaste.com";

  const handleWhatsAppClick = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=918630770389&text&type=phone_number&app_absent=0`
    );
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hr7du7l",
        "template_slpnhr5",
        form.current,
        "HzWIyP_8qlBMCTvzN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <div className="p-4 md:p-8 bg-gray-200 flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4">Nous contacter</h2>
        <div className="text-lg flex items-center mb-4">
          <span className="mr-2">
            <MdPhone />
          </span>
          {phoneNumber}
        </div>
        <div className="md:text-lg text-sm flex items-center mb-4">
          <span className="mr-2">
            <MdEmail />
          </span>
          {email}
        </div>
        <button
          onClick={handleWhatsAppClick}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 flex items-center"
        >
          <FaWhatsapp className="mr-2" /> WhatsApp
        </button>
      </div>
      <div className="max-w-2xl bg-white py-10 px-5 m-auto w-full mt-10">
        <form ref={form} onSubmit={sendEmail}>
          <div className="text-3xl mb-6 text-center">
            envoyez-nous un message
          </div>
          <div className="grid grid-cols-2 gap-4 max-w-xl m-auto">
            <div className="col-span-2 lg:col-span-1">
              <input
                type="text"
                className="border-solid border-gray-400 border-2 p-3 md:text-xl w-full"
                placeholder="nom"
                name="name"
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <input
                type="text"
                className="border-solid border-gray-400 border-2 p-3 md:text-xl w-full"
                placeholder="adresse électronique"
                name="email"
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <input
                type="text"
                className="border-solid border-gray-400 border-2 p-3 md:text-xl w-full"
                placeholder="numéro de téléphone"
                name="mail"
              />
            </div>
            <div className="col-span-2">
              <textarea
                cols="30"
                rows="8"
                className="border-solid border-gray-400 border-2 p-3 md:text-xl w-full"
                placeholder="Message"
                name="message"
              ></textarea>
            </div>
            <div className="col-span-2 text-right">
              <button
                type="submit"
                className="py-3 px-6 bg-green-500 text-white font-bold w-full sm:w-32"
                value="Send Message"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
