import { Link } from "react-router-dom";
import { useState } from "react";

const XIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

export default function Navbar2() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleMenuToggle = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const handleLinkClick = () => {
    setIsNavExpanded(false);
  };

  return (
    <header className="bg-gray-900 md:px-4 md:py-3 md:flex md:justify-between">
      <div className="flex items-center justify-between px-4 py-3 md:p-0">
        <div>
          <img className="h-12" src="/logo.png" alt="sitelogo"></img>
        </div>
        <div className="text-white text-xl pl-4">
          <p> Guide Francophone En Inde Namaste</p>
        </div>
        <div className="md:hidden">
          <button
            onClick={handleMenuToggle}
            className="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
          >
            {isNavExpanded ? (
              XIcon
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <div
        className={`${
          isNavExpanded ? "block" : "hidden"
        } md:flex md:flex-col md:items-center px-2 pt-2 pb-4 md:p-0`}
      >
        <ul className="flex flex-col md:flex-row">
          <li>
            <Link
              to="/"
              className="mt-1 block px-2 py-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Page d'accueil
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="block px-2 py-1 mt-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              A propos de
            </Link>
          </li>
          <li>
            <Link
              to="/reviews"
              className="block px-2 py-1 mt-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Témoignages
            </Link>
          </li>

          <li>
            <Link
              to="/destinations"
              className="block px-2 py-1 mt-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Destinations
            </Link>
          </li>
          <li>
            <Link
              to="/blogs"
              className="block px-2 py-1 mt-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Blogs
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="block px-2 py-1 mt-1 text-white font-semibold hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Contactez-nous
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}
