import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Orissa = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Visite d'Odhisa</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bhubaneswar2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Bhubaneswar</h2>
        <p>
          À votre arrivée à Bhubaneswar, la capitale de l'Odisha, vous serez
          chaleureusement accueilli par notre représentant qui vous aidera pour
          le transfert à votre hôtel. Prenez le temps de vous détendre et de
          vous acclimater à votre nouvel environnement. Vous pouvez explorer les
          environs ou goûter à la cuisine locale pour découvrir la riche culture
          de la région.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/malkangiri.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Bhubaneswar - Malkangiri</h2>
        <p>
          Après un petit-déjeuner revigorant, partez pour un trajet pittoresque
          de Bhubaneswar à Malkangiri. En traversant des paysages pittoresques,
          plongez dans la beauté naturelle de la région. Malkangiri est réputée
          pour son atmosphère paisible, ce qui en fait un endroit parfait pour
          se ressourcer et se connecter à la nature. Visitez les attractions
          locales, rencontrez les habitants chaleureux et découvrez leurs
          coutumes et traditions.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/koraput.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Malkangiri - Koraput</h2>
        <p>
          Poursuivez votre voyage en quittant Malkangiri et en vous dirigeant
          vers Koraput. Koraput est une région culturellement riche, habitée par
          différentes tribus indigènes. Explorez les villages tribaux, découvrez
          leur mode de vie unique et interagissez avec les habitants pour mieux
          comprendre leurs traditions et leurs formes d'art. Visitez le célèbre
          temple Jagannath à Koraput, réputé pour sa beauté architecturale et sa
          signification religieuse.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/rayagadh.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Koraput - Rayagada</h2>
        <p>
          Quittez Koraput et rendez-vous à Rayagada. En chemin, faites une halte
          à Chatikona, un petit village réputé pour son animé marché tribal
          hebdomadaire. Ce marché est un lieu d'activité intense où vous pourrez
          observer les tribus locales échanger leurs produits et leurs
          artisanats. Plongez dans l'atmosphère vivante et imprégnez-vous des
          couleurs, des sons et des parfums du marché. Ensuite, reprenez votre
          voyage vers Rayagada et installez-vous dans votre hébergement pour
          vous reposer.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/gopalpur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Rayagada - Chatikona - Gopalpur</h2>
        <p>
          Aujourd'hui, dites au revoir à Rayagada et partez pour la ville
          côtière de Gopalpur. En chemin, faites à nouveau une halte à Chatikona
          pour admirer la scène envoûtante de milliers de personnes tribales se
          rassemblant pour le marché hebdomadaire. Ce marché animé offre un
          aperçu fascinant de la vie quotidienne et des traditions des tribus
          locales. Après la visite du marché, poursuivez votre voyage jusqu'à
          Gopalpur. Gopalpur est réputée pour ses plages immaculées et son
          ambiance tranquille, offrant une occasion idéale pour se détendre et
          se ressourcer.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/puri.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Gopalpur - Lac Chilika - Puri</h2>
        <p>
          Quittez Gopalpur et partez pour une excursion captivante au lac
          Chilika, le plus grand lagon d'eau saumâtre d'Asie. Montez à bord d'un
          bateau et naviguez à travers les eaux tranquilles, en admirant la
          beauté naturelle àcouper le souffle et en observant la riche
          biodiversité du lac. Gardez un œil attentif sur les oiseaux
          migrateurs, car le lac Chilika est un véritable paradis pour les
          ornithologues. Après la balade en bateau, continuez votre voyage vers
          la ville sainte de Puri.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/konark.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Puri - Konark - Puri (via Ajmer)</h2>
        <p>
          Aujourd'hui, plongez dans les merveilles spirituelles et
          architecturales de Puri et de ses environs. Commencez votre journée
          par une visite du vénéré temple de Jagannath, où vous pourrez assister
          aux rituels quotidiens et vous imprégner de l'atmosphère spirituelle.
          Ensuite, partez pour une excursion vers le célèbre temple du Soleil à
          Konark, classé au patrimoine mondial de l'UNESCO, connu pour son
          architecture impressionnante représentant le char du dieu Surya.
          Admirez les sculptures complexes et appréciez la grandeur de ce
          chef-d'œuvre architectural. Revenez à Puri en fin de journée et
          profitez du temps libre pour explorer les marchés animés ou vous
          détendre sur la plage.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bhubaneswar3.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Puri - Bhubaneswar</h2>
        <p>
          Quittez Puri et retournez à Bhubaneswar. Visitez les attractions
          locales de Bhubaneswar, y compris les anciennes temples comme le
          temple de Lingaraja et le temple de Mukteshwar. Imprégnez-vous de la
          spiritualité et de la beauté architecturale de ces sites sacrés.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/bhubaneswar4.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Bhubaneswar (Visite locale)</h2>
        <p>
          Passez la journée à explorer les sites historiques et culturels de
          Bhubaneswar, tels que les grottes d'Umy-4 p-4 bg-white rounded-lg
          shadowagiri et Khandagiri, le musée d'État de l'Odisha et le parc
          zoologique de Nandankanan. Plongez dans l'histoire ancienne de la
          région et découvrez sa richesse culturelle.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Bhubaneswar - Départ</h2>
        <p>
          {" "}
          Après le petit-déjeuner, en fonction de l'heure de votre départ, vous
          pouvez faire du shopping de dernière minute ou explorer davantage la
          ville avant d'être transféré à l'aéroport pour votre vol de départ.
          Emportez avec vous des souvenirs inoubliables de votre voyage en
          Odisha.
        </p>{" "}
      </div>
    </div>
  );
};

export default Orissa;
