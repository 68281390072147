import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const GoldenTriangle = () => {
  return (
    <div className=" bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">
      Circuit de Triangle d'or
      </h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2" >Jour 1 : Arrivée à Delhi</h2 >
        <p>
          À votre arrivée à l'aéroport de Delhi, vous serez accueilli et
          transféré à votre hôtel. Prenez un moment pour vous détendre et vous
          rafraîchir. Profitez du reste de la journée pour vous familiariser
          avec la ville et vous acclimater à l'ambiance animée de Delhi.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/delhi3.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 2 : Visite de Delhi</h2 >
        <p>
          Explorez la ville de Delhi en visitant ses principales attractions
          touristiques. Commencez par la vieille ville, où vous pourrez
          découvrir la majestueuse Jama Masjid, la plus grande mosquée de
          l'Inde, et vous promener dans les ruelles animées de Chandni Chowk.
          Ensuite, visitez le Fort Rouge, un symbole de la grandeur passée de
          l'empire moghol. Terminez votre journée par une visite du mémorial de
          Mahatma Gandhi, Raj Ghat.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/agra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 3 : Delhi à Agra</h2 >
        <p>
          Quittez Delhi et partez pour Agra, située à environ 200 km au sud. À
          votre arrivée à Agra, enregistrez-vous à votre hôtel et détendez-vous
          un peu. L'après-midi, visitez le célèbre Taj Mahal, l'une des
          merveilles du monde, construite par l'empereur moghol Shah Jahan en
          mémoire de son épouse bien-aimée. Admirez l'architecture magnifique et
          les jardins pittoresques qui entourent le Taj Mahal.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/agra2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 4 : Agra à Jaipur</h2 >
        <p>
          Le matin, visitez le fort d'Agra, une forteresse massive datant du
          XVIe siècle. Explorez ses palais, ses salles d'audience et ses jardins
          luxuriants. Ensuite, quittez Agra et partez pour Jaipur, la capitale
          du Rajasthan, située à environ 240 km à l'ouest d'Agra. À votre
          arrivée à Jaipur, enregistrez-vous à votre hôtel et profitez du reste
          de la journée à votre guise.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/jaipur.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 5 : Visite de Jaipur</h2 >
        <p>
          Passez la journée à explorer les merveilles de Jaipur. Commencez par
          une visite du fort d'Amber, une forteresse majestueuse située sur une
          colline, où vous pourrez profiter d'une balade à dos d'éléphant
          jusqu'à l'entrée du fort. Ensuite, visitez le palais de la ville, qui
          abrite un musée et de magnifiques cours intérieures. Explorez le
          Jantar Mantar, un observatoire astronomique du XVIIIe siècle, avant de
          vous promener dans les bazars animés de la vieille ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/jaipur2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 6 : Visite de Jaipur</h2 >
        <p>
          Poursuivez votre exploration de Jaipur en visitant d'autres
          attractions emblématiques de la ville. Admirez le Hawa Mahal,
          également connu sous le nom de "Palais des Vents", qui présente une
          façade en treillis unique. Ensuite, rendez-vous au palais des miroirs,
          le Sheesh Mahal, situé à l'intérieur du palais de la ville. Profitez
          de l'après-midi pour découvrir l'artisanat local, notamment les
          textiles, la poterie et les bijoux, dans les bazars de Jaipur.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/delhi2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 7 : retour à Delhi</h2 >
        <p>
          Après le petit-déjeuner, quittez Jaipur et partez pour Delhi. À votre
          arrivée à Delhi, enregistrez-vous à votre hôtel et profitez du reste
          de la journée pour vous reposer ou faire du shopping dans les bazars
          animés de la ville. Vous pouvez également savourer un dernier dîner
          dans l'un des restaurants réputés de Delhi, en goûtant à la délicieuse
          cuisine locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-semibold" >Jour 7 : départ le même jour pour la France</h2 >
        <p>Arrivée à Delhi et départ pour la France le même jour.</p>
      </div>
    </div>
  );
};

export default GoldenTriangle;
