import React from "react";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div className="container mx-auto p-6">
      <Helmet>
        <meta
          name="description"
          content="Bienvenue au Guide Francophone en Inde, où nous sommes passionnés par la création de voyages uniques et personnalisés qui vous immergent dans la riche tapisserie de l'Inde.Nos guides individuels sont des experts dans l'exploration de diverses destinations, des palais majestueux du Rajasthan aux temples sacrés du Tamil Nadu.Grâce à des circuits sur mesure, nous vous offrons la possibilité de découvrir toutes les merveilles de l'Inde, qu'il s'agisse d'escapades aventureuses ou d'expéditions culturelles et historiques.Admirez l'emblématique Taj Mahal, découvrez d'anciens temples et palais, et explorez des villes dynamiques comme Delhi, Kolkata et Mumbai.Nos guides multilingues veillent à ce que vous viviez une expérience transparente et enrichissante, tout en savourant les spécialités régionales, les délices de la rue et la cuisine locale traditionnelle. Rejoignez-nous pour un voyage où chaque étape est guidée par une profonde expertise locale, rendant votre voyage en Inde vraiment inoubliable."
        />
        <meta
          name="keywords"
          content="guides individuels, explorant diverses destinations en Inde, sites touristiques, palais du Rajasthan, explorer les temples sacrés du Tamil Nadu, circuits touristiques, voyage personnalisées et uniques, voyage en Inde, découvrir toutes les merveilles de l'Inde, aventure en Inde, Visites culturelles et historiques, Taj Mahal, la visite d'anciens temples et palais, visite de ville personnalisée, promenades et visites guidées, Delhi, Kolkata, Mumbai, découvrir spécialités régionales, délices de la cuisine de rue et cuisine locale traditionnelle, guides francophones, guides anglophones, Expertise locale, Guides multilingues."
        />
      </Helmet>
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-2">A propos de nous</h1>
          <h3 className="text-xl font-semibold">
            Services de guidage pour les clients français et anglais en Inde
          </h3>
          <p className="mt-4 text-justify">
            Notre équipe de guides individuels vous souhaite la bienvenue.
            Chacun de nous se consacre à offrir des services de guidage
            exceptionnels pour les clients francophones et anglophones explorant
            diverses destinations en Inde. Nous sommes passionnés par la beauté
            naturelle et les diverses traditions de ce pays incroyable.
          </p>
          <p className="mt-4 text-justify">
            Tous nos guides expérimentés et professionnels ont une connaissance
            approfondie des différentes régions de l'Inde, de ses cultures, de
            son histoire et de ses sites touristiques. Que vous souhaitiez
            découvrir? les majestueux palais du Rajasthan, explorer les temples
            sacrés du Tamil Nadu ou vous immerger dans la spiritualité de
            Varanasi,parcourir des sites moins connus des circuits
            touristiques,… nous sommes là pour vous guider tout au long de votre
            voyage.
          </p>{" "}
          <p className="mt-4 text-justify">
            Nous comprenons l'importance de créer des expériences de voyage
            personnalisées et uniques pour nos clients. Nous vous aiderons à
            planifier votre itinéraire, en tenant compte de vos intérêts, de
            votre budget et de vos préférences. Que vous soyez à la recherche
            d'une aventure excitante, d'une retraite spirituelle, d'un voyage
            culinaire ou d'une découverte culturelle,d'un voyage culinaire,
            d’une immersion dans des palais ou d’un trek direction l’Himalaya,
            nous nous efforcerons de rendre votre voyage en Inde inoubliable.
          </p>{" "}
          <p className="mt-4 text-justify">
            En plus de nos compétences en matière de guidage, nous nous
            engageons à offrir un excellent service client. Nous sommes
            disponibles pour répondre à vos questions, vous aider en cas de
            besoin et vous assurer une expérience sans tracas pendant votre
            séjour en Inde. Votre satisfaction est notre priorité absolue.
          </p>{" "}
          <p className="mt-4 text-justify">
            Que vous soyez un voyageur individuel, un couple, une famille ou un
            groupe d'amis, nous sommes là pour vous accompagner et vous faire
            découvrir toutes les merveilles de l'Inde. Faites-nous confiance
            pour vous offrir un voyage enrichissant et inoubliable.
            Contactez-nous dès aujourd'hui pour commencer à planifier votre
            prochaine aventure en Inde !
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-bold mb-4">Services offerts:</h3>
          <ul>
            <li className="mb-4 text-justify">
              <b className="font-semibold ">
                Visites culturelles et historiques:
              </b>{" "}
              Nos guides compétents vous emmèneront dans des visites culturelles
              et historiques immersives, vous donneront un aperçu détaillé de
              l'histoire, de l'architecture et des coutumes fascinantes de
              l'Inde. De l'exploration de sites emblématiques comme le Taj Mahal
              ou la visite d'anciens temples et palais, nous donnons vie à
              l'histoire grâce à des récits captivants.
            </li>

            <li className="mb-4 text-justify">
              <b className="font-semibold">
                Promenades et visites de la ville:
              </b>{" "}
              Nous proposons une visite de ville personnalisée, avec promenades
              et visites guidées, vous permettant de découvrir les joyaux cachés
              et les quartiers animés des villes indiennes les rues chaotiques
              mais captivantes de Delhi, la ville coloniale de charme de Kolkata
              ou l'énergie dynamique qui se dégage de Mumbai. Nos guides peuvent
              vous guider à travers les ces paysages urbains variés, colorés
              entre sons et saveurs méconnues.
            </li>

            <li className="mb-4 text-justify">
              <b className="font-semibold">Tours de trekking et d'aventure:</b>{" "}
              Pour les aventureux (euses), nous préconisons des conseils
              d'experts pour les randonnées et les circuits d'aventure dans
              endroits pittoresques tels que l'Himalaya, les Ghâts occidentaux
              et plus. Nos guides assurent votre sécurité tout en vous guidant
              terrains exigeants, partageant leur connaissance de la flore
              locale, la faune et des communautés indigènes le long du chemin.
            </li>

            <li className="mb-4 text-justify">
              <b className="font-semibold">
                Visites culinaires et gastronomiques:
              </b>{" "}
              Découvrez les saveurs incroyables de l'Inde avec nos visites
              culinaires et gastronomiques spécialisées. Nos guides vous
              emmèneront dans un voyage gastronomique, vous faisant découvrir
              spécialités régionales, délices de la cuisine de rue et cuisine
              locale traditionnelle tout en restant très attentif à la qualité
              des mets proposés. Des épices parfumées aux collations de rue
              appétissantes, savourez les saveurs authentiques de l'Inde avec
              nos conseils. La cardamone, le curcuma, la coriandre, le curry, le
              poivre égailleront vos papille.
            </li>

            <li className="mb-4 text-justify">
              <b className="font-semibold">Itinéraires personnalisés:</b> Nous
              comprenons que chaque voyageur a des préférences et des intérêts
              uniques. Notre équipe de guides est habile à créer des itinéraires
              personnalisés adaptés à vos besoins spécifiques. Que vous soyez
              intéressé(e) par un voyage découverte, les retraites spirituelles,
              l'art et la culture, les sports d'aventure ou une combinaison de
              expériences, nous organiserons un voyage mémorable rien que pour
              vous !
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-2xl font-bold mb-4">Pourquoi nous choisir:</h3>
          <ul>
            <li className="mb-4 text-justify">
              <b className="font-semibold">Guides multilingues:</b> Notre équipe
              est composée de guides francophones français et des guides
              anglophones compétents qui assurent une communication efficace et
              une parfaite compréhension, vous offrant une expérience
              transparente tout au long de votre séjour en Inde.
            </li>
            <li className="mb-4 text-justify">
              <b className="font-semibold">Expertise locale:</b> En tant que
              guides expérimentés avec une connaissance approfondie de l'Inde,
              nous offrons un aperçu authentique de la destinations, de la
              culture et des traditions. Nous désirons vous offrir une
              expérience immersive qui va au-delà des attractions touristiques.
            </li>
            <li className="mb-4 text-justify">
              <b className="font-semibold">Attention personnalisée:</b> Nous
              sommes fiers de livrer une attention personnalisée à chaque
              client. Nos guides seront à votre écoute selon vos préférences,
              pour répondre à vos questions et adapter la visite en fonction de
              votre rythme et de vos centres d'intérêt, vous garantissant un
              séjour vraiment mémorable et expérience agréable.
            </li>
            <li className="mb-4 text-justify">
              <b className="font-semibold">Sécurité et confort:</b> Votre
              sécurité et votre confort sont notre priorité priorités. Nos
              guides sont formés pour gérer différentes situations et fournir
              une assistance chaque fois que nécessaire. Nous sélectionnons avec
              soin des options de transport et d'hébergement qui répondent à des
              normes élevées de sécurité et de confort.
            </li>
            <li className="mb-4 text-justify">
              <b className="font-semibold">
                Expériences mémorables pour un circuit inoubliable:
              </b>{" "}
              Nous visons à créer des souvenirs durables pour nos clients. Avec
              nos guides passionnés et attachants, vous pouvez vous attendre à
              des interactions (rencontres ? échanges ? ) significatives, des
              expériences authentiques, et un lien profond avec les lieux que
              vous visiterez.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
