import SliderBanner from "../components/SliderBanner";
import AboutComponent from "../components/AboutComponent";
import CustomToursComponent from "../components/CustomTourComponent";
import OwnerTile from "../components/OwnerTitle";
import GuideCardsCarousel from "../components/GuideCardsCarousel";
import HomeBlogs from "./HomeBlogs";
import { Helmet } from "react-helmet-async";
import FoodTour from "../components/FoodTour";
import YogaTourComponent from "../components/YogaTourComponent";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>
          Guide Francophone | Guide Francophone en Inde | Voyage en Inde
        </title>
        <meta
          name="description"
          content="Voyagez en Inde avec le Guide Francophone en Inde Namaste, nous fournissons des services pour les clients français et anglais. "
        />
        <meta
          name="keywords"
          content="Guide francophone en Inde, Voyage en Inde, Vacances en Inde, Vous sur mesure en Inde, Festival de Rann à Kutch, Festival du cerf-volant à Gujrat, voyage de triangle d'or, tour de l'Inde du Sud, Voyage au Gujrat, Voyage au Odisha, Voyage au Rajasthan, Voyage au centre d'Inde, Voyage au Tamil Nadu, Voyage au Kerla, Voyage au Karnataka, Voyage au Goa, Visite Delhi, Visite Qutub Minar, Visite Agra, Visite Taj Mahal, Visite  Fort d'Amber, Visite Jaipur, Visite Ville Rose, Visite Udaipur, Visite Cité palais, Visite Jodhpur, Visite Mehrangarh Fort, Visite Ville bleue, Visite Jaisalmer, Visite Ville d'or, Visite Bikaner, Visite junagarh fort, Visite Deshnok, Visite temple Rats  , Visite Mandawa, Visite parc national de Keoladeo, Visite Havelis, Visite Bharatpur, Visite Gwalior, Visite Orcha, Visite Khajuraho, Visite Gars, Visite Benaras, Visite Puducherry, Visite Pondichery, Visite Mahabalipuram, Visite Madurai, Visite Cochin, Guide Local au Rajasthan, Guide sur place au Rajasthan, Guide privée en inde, Guide accompagnateur en inde, Guide accompagnateur à l'Inde du Nord, Guide accompagnateur à l'Inde du Sud,service de Guide local à l'Inde du Nord,  Service de Guide local à l'Inde du Sud, Local tour opérateur à Delhi, local tour opérateur à Agra, local tour opérateur à Jaipur,réservation de taxi, réservation d'hôtel, réservation de train, visite de la ville en voiture, transport local pour la visite de la ville, voiture privée pour tringle d'or avec chauffeur parlant anglais, meilleur service de voiture Rajasthan, meilleur service de voiture pour voyage au Rajasthan.  service de voiture Agra, service de voiture delhi"
        />
      </Helmet>
      <AboutComponent />
      <SliderBanner />
      <YogaTourComponent />
      <CustomToursComponent />
      <FoodTour />
      <OwnerTile />
      <GuideCardsCarousel />
      <HomeBlogs />
    </div>
  );
}
