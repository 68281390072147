import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Himanchal = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Randonnée en montagne</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          À votre arrivée à l'aéroport de Delhi, vous serez accueilli et
          transféré à votre hôtel. Profitez de la journée pour vous reposer,
          vous remettre du voyage et vous acclimater à l'altitude avant de
          commencer votre aventure de trekking.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek1.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi à Bijal</h2>
        <p>
          Quittez Delhi et partez en direction de Bijal, qui servira de point de
          départ pour votre trek. Une fois arrivé à Bijal, installez-vous au
          camp de base, préparez votre équipement et familiarisez-vous avec
          l'environnement.
        </p>{" "}
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Bijal à Rajthati (2400 mètres)</h2>
        <p>
          Débutez votre trek depuis Bijal en direction de Rajthati. Marchez à
          travers des paysages pittoresques, des forêts verdoyantes et des
          sentiers montagneux, en découvrant la beauté naturelle de la région.
          Une fois arrivé à Rajthati, installez votre campement pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek3.png"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Rajthati à Bunga (3100 mètres)</h2>
        <p>
          Continuez votre trek de Rajthati à Bunga, en grimpant en altitude et
          en profitant de vues panoramiques sur les montagnes environnantes. Ce
          tronçon du trek offre des paysages époustouflants et des défis
          excitants. Établissez votre camp à Bunga pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek4.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Bunga à Sari Kandha</h2>
        <p>
          Poursuivez votre trek de Bunga à Sari Kandha, en traversant des cols,
          des vallées et des rivières. Admirez la beauté naturelle qui vous
          entoure et ressentez la sérénité de la nature intacte. Installez votre
          camp à Sari Kandha pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek5.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Sari Khanda à Bijal</h2>
        <p>
          Revenez sur vos pas de Sari Kandha à Bijal, en redescendant lentement
          en altitude. Profitez des dernières vues sur les majestueuses
          montagnes et revivez les moments forts de votre aventure en montagne.
          Une fois arrivé à Bijal, installez votre campement pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/trek7.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Bijal à Delhi</h2>
        <p>
          Terminez votre trek en retournant de Bijal à Delhi. Profitez du trajet
          de retour pour vous reposer, échanger des anecdotes et des souvenirs
          avec vos compagnons de trek et réfléchir à votre expérience
          inoubliable dans les montagnes.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img  style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Delhi (Départ)</h2>
        <p>
          Profitez de votre dernière journée à Delhi pour explorer la ville,
          faire du shopping ou vous détendre avant votre vol de retour.
          Transfert à l'aéroport de Delhi pour votre départ, emportant avec vous
          des souvenirs inoubliables et des expériences enrichissantes de votre
          trek en montagne.
        </p>
      </div>
    </div>
  );
};

export default Himanchal;
