import React from "react";
import { FaUtensils, FaLandmark, FaCamera } from "react-icons/fa";

const backgroundStyle = {
  backgroundImage: `url('back.jpg')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  // Set the height of the background image to cover the entire viewport
};

const FoodTour = () => {
  return (
    <div
      style={backgroundStyle}
      className="text-white flex flex-col justify-center items-center my-2"
    >
      <div className="container mx-auto text-center bg-black bg-opacity-75 p-8 rounded-lg">
        <h1 className="text-4xl font-bold mb-4">Visite d'une journée</h1>
        <p>Disponible à Delhi, Agra, Jaipur et Udaipur</p>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gray-800 p-6 rounded shadow flex flex-col items-center">
            <FaUtensils className="text-5xl mb-2" />
            <h2 className="text-2xl font-bold mb-2">Tour de Cuisine</h2>
            <p>
              Découvrez la joie de cuisiner des plats traditionnels de
              différentes cuisines.
            </p>
          </div>
          <div className="bg-gray-800 p-6 rounded shadow flex flex-col items-center">
            <FaLandmark className="text-5xl mb-2" />
            <h2 className="text-2xl font-bold mb-2">Balade du Patrimoine</h2>
            <p>
              Explorez le riche patrimoine culturel des sites historiques et des
              monuments.
            </p>
          </div>
          <div className="bg-gray-800 p-6 rounded shadow flex flex-col items-center">
            <FaCamera className="text-5xl mb-2" />
            <h2 className="text-2xl font-bold mb-2">Tour de Séance Photo</h2>
            <p>
              Capturez de beaux moments dans des lieux emblématiques avec des
              photographes professionnels.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodTour;
