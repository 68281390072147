import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Gujarat = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">
      Circuit du Gujrat
      </h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/aham.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : France vers Ahmedabad</h2>
        <p>
          Arrivée à Ahmedabad, en Inde, en provenance de France. Enregistrement
          à votre hôtel et temps de repos pour vous acclimater à la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/aham2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Ahmedabad</h2>
        <p>
          Explorez la vibrante ville d'Ahmedabad, connue pour son riche
          patrimoine culturel et ses sites historiques. Visitez le Sabarmati
          Ashram, l'ancienne résidence de Mahatma Gandhi, et découvrez ses
          principes de non-violence. Explorez l'architecture complexe de la Jama
          Masjid et de la mosquée Sidi Saiyyed. Promenez-vous dans les rues
          animées de la vieille ville et imprégnez-vous de la culture locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/poshina.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Ahmedabad à Poshina</h2>
        <p>
          Quittez Ahmedabad et rendez-vous à Poshina, un petit village situé
          dans les collines d'Aravalli, connu pour son patrimoine tribal.
          Explorez les villages tribaux, interagissez avec les communautés
          locales et découvrez leur mode de vie unique. Visitez le temple
          d'Ambaji, un lieu de pèlerinage populaire pour les hindous.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/patan.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Poshina à Patan</h2>
        <p>
          Voyagez de Poshina à Patan, une ville célèbre pour ses magnifiques
          saris en soie Patola. Visitez le Rani ki Vav, un puits à étages classé
          au patrimoine mondial de l'UNESCO. Explorez les temples anciens et les
          sites historiques de la ville.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/mod.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Patan - Modhera - Zainabad</h2>
        <p>
          Visitez le temple du soleil de Modhera, connu pour son architecture
          époustouflante et ses sculptures détaillées. Poursuivez votre voyage
          vers Zainabad, un village situé dans le petit désert du Rann de Kutch.
          Explorez le paysage unique et la faune de la région, notamment l'âne
          sauvage indien en voie de disparition.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/muli.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Zainabad à Muli</h2>
        <p>
          Voyagez de Zainabad à Muli, une ville connue pour son importance
          historique. Visitez le temple jain de Muli et explorez les marchés
          locaux pour découvrir la culture locale.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/mandvi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Muli - Gandhidham - Mandvi</h2>
        <p>
          Quittez Muli et partez pour Gandhidham. Visitez le complexe de sel de
          Gandhidham Adipur, où vous pourrez en apprendre davantage sur le
          processus de fabrication du sel. Poursuivez jusqu'à Mandvi, une ville
          côtière réputée pour ses belles plages et le palais historique de
          Vijay Vilas. Profitez d'un moment de détente sur la plage.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/bhuj.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Mandvi à Bhuj</h2>
        <p>
          Rendez-vous à Bhuj, le centre culturel de la région de Kutch. Explorez
          les monuments historiques, dont l'Aina Mahal et le Prag Mahal. Visitez
          le village de Bhujodi pour découvrir les techniques traditionnelles de
          tissage artisanal.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/kutch.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Bhuj - Kutch - Bhuj</h2>
        <p>
          Partez pour une excursion d'une journée dans le Grand Rann de Kutch,
          une vaste étendue salée qui se transforme en un désert blanc pendant
          la saison sèche. Découvrez lepaysage unique et découvrez la culture
          locale des Kutchi.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/hodka.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Bhuj - Nirona - Hodka</h2>
        <p>
          Visitez le village de Nirona, connu pour son art traditionnel du Rogan
          et son travail du laque. Observez les artisans créer de magnifiques
          œuvres d'art avec ces techniques uniques. Poursuivez jusqu'au village
          de Hodka et plongez dans l'artisanat local et la musique
          traditionnelle.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/morbi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Hodka - Morbi - Rajkot</h2>
        <p>Conduisez de Hodka à Morbi.</p>
        <p>
          Voyagez de Hodka à Morbi, une ville renommée pour son industrie de la
          céramique. Visitez les usines de céramique et découvrez l'artisanat
          minutieux. Poursuivez votre voyage jusqu'à Rajkot, lieu de naissance
          de Mahatma Gandhi. Visitez le Kaba Gandhi No Delo, la maison
          ancestrale de Gandhi, transformée en musée.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/jamnagar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 12 : Rajkot - Jamnagar - Dwarka</h2>
        <p>
          Dirigez-vous vers Jamnagar et visitez le fort de Lakhota et le temple
          Bala Hanuman, connu pour son chant continu du "Ram Dhun" depuis plus
          de 50 ans. Continuez jusqu'à Dwarka, une ville sacrée pour les hindous
          et le lieu de naissance du Seigneur Krishna. Visitez le temple de
          Dwarkadhish et profitez de l'atmosphère spirituelle.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/dwarka.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 13 : Dwarka - Bhet Dwarka - Dwarka</h2>
        <p>
          Faites une excursion en bateau jusqu'à l'île de Bhet Dwarka, où se
          trouve le temple de Bhet Dwarkadhish, un lieu saint pour les pèlerins.
          Explorez le temple et profitez de la beauté naturelle de l'île.
          Revenez à Dwarka pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/porbandar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 14 : Dwarka - Porbandar - Junagadh</h2>
        <p>
          Quittez Dwarka et partez pour Porbandar, ville natale de Mahatma
          Gandhi. Visitez la maison de naissance de Gandhi, Kirti Mandir, qui
          abrite un musée dédié à sa vie. Continuez jusqu'à Junagadh, une ville
          riche en histoire et en monuments. Explorez les sites historiques tels
          que le fort d'Uparkot et le mausolée d'Ashoka.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/somnath.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 15 : Junagadh - Somnath - Diu</h2>
        <p>
          Visitez le célèbre temple de Somnath, l'un des douze Jyotirlingas
          (lieux de pèlerinage dédiés à Shiva). Admirez l'architecture
          magnifique du temple et profitez de l'ambiance spirituelle. Poursuivez
          jusqu'à Diu, une petite île au bord de la mer d'Arabie, connue pour
          ses plages pittoresques et son fort historique.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/muli2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 16 : Muli - Gandhidham - Mandvi</h2>
        <p>
          Dirigez-vous vers Bhavnagar et profitez de votre temps libre pour
          explorer la ville. Visitez les attractions locales telles que le
          palais de Takhteshwar, le marché de Ghogha Circle et le temple de
          Swaminarayan.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/bhav.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 17 : Bhavnagar - Palitana - Bhavnagar</h2>
        <p>
          Faites une excursion d'une journée à Palitana, un lieu saint pour les
          jaïns, réputé pour ses temples magnifiques situéssur la colline de
          Shatrunjaya. Grimpez les 3 800 marches pour atteindre les temples et
          profitez des vues panoramiques sur la région. Retournez à Bhavnagar
          pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/vadodra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 18 : Bhavnagar - Vadodara</h2>
        <p>
          Quittez Bhavnagar et dirigez-vous vers Vadodara, une ville connue pour
          sa riche histoire et son patrimoine culturel. Explorez le magnifique
          palais de Laxmi Vilas, l'un des plus grands palais privés du monde.
          Visitez également les sites historiques tels que le mausolée de
          Maharaja Sayajirao Gaekwad III et le musée de la maison de Nandlal
          Bose.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/champ.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 19 : Vadodara - Champaner - Vadodara</h2>
        <p>
          Faites une excursion à Champaner, un site du patrimoine mondial de
          l'UNESCO, connu pour ses monuments historiques et ses vestiges
          architecturaux. Explorez les forts, les palais et les mosquées de la
          vieille ville de Champaner. Retournez à Vadodara pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 21 : Vadodara vers la France</h2>
        <p>
          Après le petit-déjeuner, effectuez les formalités de départ à votre
          hôtel. Vous serez ensuite transféré à l'aéroport de Vadodara pour
          votre vol de retour vers la France, emportant avec vous de merveilleux
          souvenirs de votre voyage à travers l'État du Gujarat en Inde.
        </p>
      </div>
    </div>
  );
};

export default Gujarat;
